// import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import App from './App';
import './apps/ACCLAIM/acclaim.css';

// import './index.css';

// const root = createRoot(document.getElementById('root'));

// root.render(
//   <React>
//       <App />
//   </React>,
// );
import { createRoot } from "react-dom/client";

createRoot(
  document.getElementById("root")
).render(<App />)
// const rootNode = document.getElementById('root');
// const root = ReactDOM.hydrateRoot(rootNode, <App />);
// const rootElement = document.getElementById('root');
// const root = createRoot(rootElement);

// root.render(
//       <App />
// );
// ReactDOM.render(<App />, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  // document.getElementById('root')
  
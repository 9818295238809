import { Dialog } from "primereact/dialog";
import React from "react";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const AcclaimModal = (props) => {
    const Navigate = useNavigate();
    const {onHide=() => {
        if (props.onHide) {
            props.onHide();
        }
        if (props.onHideGoBack) {
            Navigate("/" + window.location.pathname.replace("v2/","").replace("/details","").split("/").filter(Boolean).slice(0, -1).join("/"))
        }
    } } = props;

    const { style={} } = props;

    // const onHide = props.onHide || (() => {window.history.back()});

    const {perms, allPermissionsPresent } = useAuth()

    if (props.perm) {
        if (!allPermissionsPresent(props.perm)) {
            return <div className="dnone"/>
        }
    }

    return (
        <Dialog {...props} style={{zIndex:"1200", ...style}} onHide={() => {onHide(); console.log("closeModal")}} draggable={false}>
            {props.children}
        </Dialog>
    );
}

export default AcclaimModal;
import Cookies from "js-cookie";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { axiosPrivateInstance } from "../axios";
import { DEV_MODE } from "../variables/CoreVariables";
import useAuth from './useAuth';
// import useRefreshToken from "./useRefreshToken";


var useAxiosPrivate = () => {

        const { user,refreshToken } = useAuth()
        // const refresh = useRefreshToken()

        let navigate = useNavigate();
        useEffect(() => {
        
            const requestIntercept = axiosPrivateInstance.interceptors.request.use(
                (config) => {
                    if (!config.headers["Authorization"]) {
    
                        config.headers['X-CSRFToken'] = Cookies.get('csrftoken')
                        // config.headers['X-CSRFToken'] = csrftoken
                    }
                    config.headers["Timezone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    return config
                },
                (error) => Promise.reject(error)
            )
    
            const responseIntercept = axiosPrivateInstance.interceptors.response.use(
                response => {
                    if (response.data) {
                        let str = JSON.stringify(response.data).toLowerCase();
                        if (str.includes("bad auth") || str.includes("bad_auth")) {
                            if (!(window.location.pathname.includes("/v2/login"))) {
                                // navigate(`/login/?to=${window.location.pathname.replace("/v2/","")}`);
                            }
                        }
                    }
    
                    return response
                },
                async error => {
                    const prevRequest = error?.config;
                    if ((error?.response?.status === 403 || error?.response?.status === 401) && !prevRequest?.sent) {
                        if (!(window.location.pathname.includes("/v2/login"))) {
                            // navigate(`/login/?to=${window.location.pathname.replace("/v2/","")}`);
                        }
                        prevRequest.sent = true;
                        return {}//axiosPrivateInstance(prevRequest)
                    } else {
                        if (error.data) {
                            let str = JSON.stringify(error.data).toLowerCase();
                            if (str.includes("bad auth") || str.includes("bad_auth")) {
                                if (!(window.location.pathname.includes("/v2/login"))) {
                                    // navigate(`/login/?to=${window.location.pathname.replace("/v2/","")}`);
                                }
                            }
                        }
                    }
                    return Promise.reject(error);
                }
            )
    
            return () => {
                axiosPrivateInstance.interceptors.request.eject(requestIntercept)
                axiosPrivateInstance.interceptors.response.eject(responseIntercept)
            }
        }, [user])

    // console.log(axiosPrivateInstance);
    return axiosPrivateInstance
}

if (DEV_MODE) {
    useAxiosPrivate = ()=>{
        return axiosPrivateInstance
    }
}

export default useAxiosPrivate;
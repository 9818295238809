import { createContext, useState } from 'react';

export const AuthContext = createContext({
    user: {},
    setUser: () => { },
    proteanUser: {},
    setProteanUser: () => { },
})

export function AuthContextProvider(props) {
    const [user, setUser] = useState({});
    const [proteanUser, setProteanUser] = useState({});

    return <AuthContext.Provider value={{
        user, setUser,
        proteanUser, setProteanUser,
    }}>
        {props.children}
    </AuthContext.Provider>
}

export default AuthContext
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../axios';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Login from './Login';

export default function Logout() {

    const { setUser, setProteanUser } = useAuth()

    //Initial Load Event
    useEffect(() => {
        setUser(null);
        setProteanUser(null);
        axiosInstance.get('acclaim-react-logout/').then((result) => {})
        .catch((err) => console.log(err));
    },[]);

    return (
        <div>
            <div className='container padding1r card' style={{"margin": "auto", "margin-top":"10%"}}>
                <div className='padding1r'>
                    <h2>Logged Out</h2>
                    <p>You have successfully logged out!</p>
                </div>
            </div>
            <Login/>
        </div>
    )
}
import { Menu } from "primereact/menu";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { AcclaimIconButton } from "./AcclaimButtons";
const AcclaimIcon = (props) => {

    const {perms, allPermissionsPresent } = useAuth()
    const navigate = useNavigate();

    if (props.perm) {
        if (!allPermissionsPresent(props.perm)) {
            return <div className="dnone"/>
        }
    }

    const clickHandler = () => {
        if (typeof link !== "") {
            navigate(props.link);
        }
        onClick(); 
        // window.location.href = props.link;
    }

    const {title="", icon="", link="", children="", invert=false, text=null, onClick=()=>{}} = props;
    return (
        <div title={title} className={`AcclaimIcon icon pmd-ripple-effect ${text !== null ? "AcclaimTextIcon" : ""}`} style={{borderRadius:"0px","height":"100%",minWidth: "32px"}} onClick={clickHandler}>
            <span style={{...(invert ? {"filter": "invert(1)"} : {}), ...(icon !== "" ? {backgroundImage:`url(/v2/static/icons/${icon}.svg)`} : {}), fontSize: "17px"}}>{text === null ? null : text}</span>
            {children !== "" ? children : null}
        </div>
    );
}

const AcclaimIconDropdown = (props) => {
    const {perms, allPermissionsPresent } = useAuth();
    const [dropDownData,setDropDownData] = useState(null);
    const [dropDownActive,setDropdownActive] = useState(false);
    const [dropdownPosition,setDropdownPosition] = useState([0,0]);
    const linksMenu = useRef(null);
    useEffect(() => {
        let finalData = [];
        let tempData = props.model;
        for (let i=0;i<tempData.length;i++) {
            let currentDict = tempData[i];
            let disabled = false;
            if (typeof tempData[i]["disabled"] !== "undefined") {
                disabled = tempData[i]["disabled"];
            }
            console.log(tempData[i]["label"]);
            console.log(disabled);
            currentDict["template"] = () => {return defaultRenderTemplate(tempData[i]["label"],tempData[i]["url"], disabled)}
            finalData.push(currentDict);
        }
        setDropDownData(finalData);
    },[])

    if (props.perm) {
        if (!allPermissionsPresent(props.perm)) {
            return <div className="dnone"/>
        }
    }
    
    const clickHandler = (e) => {
        window.location.href = props.link;
    }

    const eventHandler = (e) => {
        linksMenu.current.toggle(e);
    }

    const defaultRenderTemplate = (text, link, disabled) => {
        return (
            (!disabled) ? 
            <a href={link} target="_blank" className="p-menuitem-link">
                <span className="p-menuitem-text">{text}</span>
            </a>
            :
            <a href={link} className="p-menuitem-link" style={{background: "rgb(235 235 235)", color: "white"}}>
                <span className="p-menuitem-text">{text}</span>
            </a>
        )
    }

    return(
        <div>
            {((dropDownData !== null) ? <Menu model={dropDownData} popup ref={linksMenu}/> : null)}
            <AcclaimIconButton icon={props.icon} title={props.title} link={props.link} onClick={eventHandler}></AcclaimIconButton>
        
        </div> 
    )
}

export { AcclaimIcon, AcclaimIconDropdown };


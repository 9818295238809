import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { axiosInstance } from '../../axios';
import { getParam, isBlank, isNullOrUndefined } from '../../functions/CoreFunctions';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { djangoURL } from "../../variables/CoreVariables";
import { AcclaimButton } from '../../components/AcclaimButtons';
import FlexGroup from '../../components/FlexGroup';







// const Login = ({Component, props}) => {
//     const {
//         djacc,
//         setdjacc,
//         djref,
//         setdjref
//     } = useContext(globalContext);

//     const [LoginData, setLoginData] = useState({username:"", password:""});

//     const changeLoginData = (dataFieldName, value) => {
//         setLoginData({
//             ...LoginData,
//             [dataFieldName]: value
//         });
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         console.log(e);
//         const data = new FormData(e.target);
//         var entries = [...data.entries()];
//         var temp = {};
//         for (let i=0;i<entries.length;i++) {
//             temp[entries[i][0]] = entries[i][1];
//         }
//         axios.post(`${djangoURL}/api/acclaim-react-auth/`, temp)
//             //   .get(`http://192.168.16.225:8888/sales/getSFGDocument?url=c73fea6d-85d8-4cf2-ace0-d6fb3d39f696.pdf`)
//             .then((result) => {
//                 checkDjToken(result);
//                 if (Object.keys(result.data).includes("detail")) {
                    
//                 }
//                 if (Object.keys(result.data).includes("access")) {
//                     setdjacc(result.data["access"]);
//                     setdjref(result.data["refresh"]);
//                 }
//                 console.log(result.data);
//                 // setChargerData(result.data);
//                 // setExportColumns(visibleColumns.map((col) => ({ title: visibleColumns.header, dataKey: visibleColumns.field })));
//             })
//             .catch((err) => console.log(err));
//     }
//     return (
//         <div className="container">
//             <AcclaimCard className="padding1r dgrid">
//             {/* <form> */}
//             <form onSubmit={handleSubmit}>
//                 <AcclaimTextBox fieldName="Username" dataFieldName="username" data={LoginData} formFieldChange={changeLoginData} name="username"/>
//                 <AcclaimTextBox fieldName="Password" dataFieldName="password" data={LoginData} formFieldChange={changeLoginData} name="password" type="password" />
//                 <input type="submit" value="Submit" />
//             </form>
//                 <Button>{djacc}</Button>
//                 <Button>{djref}</Button>
//             </AcclaimCard>
//         </div>
//     );
// }
// export { Login };


export default function Login() {

    const { setUser, user } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const [username, setUsername] = useState()
    const [showUsernamePassword, setShowUsernamePassword] = useState(true);
    const [password, setPassword] = useState()
    const [error, setError] = useState("")
    const axiosPrivate = useAxiosPrivate()

    function onUsernameChange(event) {
        setUsername(event.target.value)
    }

    function onPasswordChange(event) {
        setPassword(event.target.value)
    }

    async function onSubmitForm(event) {
        event.preventDefault()

        setLoading(true)

        try {
            const response = await axiosInstance.post('acclaim-react-auth/', JSON.stringify({
                username,
                password
            })).catch((error) => {
                console.log(error);
                setError(error.response.data.status);
            })

            setUsername()
            setPassword()
            setLoading(false)

            try {
                // await refresh()
                const { data } = await axiosPrivate.get('u');

                console.log("======================================");
                console.log("data");
                console.log(data);
                console.log("======================================");

                setUser(data);
            } catch (error) {
                console.log(error.message)
            }

        } catch (error) {
            setLoading(false)
            // TODO: handle errors
        }
    }

    useEffect(()=>{
        console.log(`USER: ${JSON.stringify(user)}`);
        let to = getParam("to");
        if (user) {
            if (isNullOrUndefined(to) || isBlank(to)) {
                if (user.type == "portal") {
                    navigate(`/customer_portal/`);
                } else {
                    window.location.href = `${djangoURL}/`;
                }
            } else {
                window.location.replace(`${window.location.protocol}//${window.location.host}/v2/${to}`);
            }
        }
    }, [user])

    const handleMicrosoftLogin = () => {
        window.location.href = `${djangoURL}/accounts/microsoft/login/`;  // This redirects the user to the Microsoft OAuth2 login
    };

    const handleGoogleLogin = () => {
        window.location.href = `${djangoURL}/accounts/google/login/`;  // This redirects the user to the Microsoft OAuth2 login
    };

    return (
        // <div style={{"width": "100vw", "height": "100vh"}}>
        <div style={{"height": "100vh"}} className='d-flex'>
            <div id="container">
                <div id="header">
                    <div id="branding">
                        <h1 id="site-name"><a href="/">ACCLAIM WEBAPP</a></h1>
                    </div>
                </div>
                <div className="main shifted login" id="main">
                    <FlexGroup type="column" className="content padding1r">
                    {showUsernamePassword && <div id="content" className="colM">
                            <div id="content-main">
                                {error !== "" && <h2>{error}</h2>}
                                <form action="" id="login-form" onSubmit={onSubmitForm}>
                                    <input type="hidden" name="csrfmiddlewaretoken"/>
                                    <div className="form-row">
                                        <label for="username">Username or Email Address:</label>
                                        <input type="username" name="username" autocomplete='username' autocapitalize="none" className='form-control' id="username" onChange={onUsernameChange} />
                                    </div>
                                    <div className="form-row">
                                        <label for="password">Password:</label>
                                        <input type="password" name="password" autocomplete='password' className='form-control' id="password" onChange={onPasswordChange} />
                                        <input type="hidden" name="next" value=""/>
                                    </div>
                                    {/* <div className="submit-row">
                                        <button disabled={loading} className='btn btn-success' type="submit">Login</button>
                                        <input type="submit" value="Log in"> 
                                    </div> */}
                                    <div className="submit-row">
                                        <button disabled={loading} className='button' type="submit">Login</button>
                                        {/* <input type="submit" value="Log in"/> */}
                                    </div>

                                </form>
                            </div>
                            <br className="clear"/>
                        </div>}

                        <FlexGroup type="column">
                                {(!showUsernamePassword) && <button 
                                    onClick={()=>setShowUsernamePassword(true)}
                                    className="ms-login-button flex-grow-1"
                                    style={{height: "60px", width: "75%", display: "flex", alignItems: "center", justifyContent: "center", border: "none", backgroundColor: "#f68b1f", color: "#333", padding: "5px 20px", borderRadius: "5px", cursor: "pointer", transition: "background-color 0.3s ease",margin: "auto",fontWeight: "bold"}}>
                                    <span style={{ fontSize: "16px", fontWeight: "500" }}>Login with Basic Username/Password</span>
                                </button>}
                                {/* <button 
                                    onClick={handleMicrosoftLogin} 
                                    className="ms-login-button flex-grow-1"
                                    style={{height: "60px", width: "75%", display: "flex", alignItems: "center", justifyContent: "center", border: "none", backgroundColor: "#2F2F2F", color: "#fff", padding: "5px 20px", borderRadius: "5px", cursor: "pointer", transition: "background-color 0.3s ease",margin: "auto"}}>
                                    <img 
                                        src={`${djangoURL}/static/Templates/images/microsoft.svg`} 
                                        alt="Microsoft Logo" 
                                        style={{
                                        width: "24px", 
                                        height: "24px", 
                                        marginRight: "10px"
                                        }}
                                    />
                                    <span style={{ fontSize: "16px", fontWeight: "500" }}>Login with Microsoft</span>
                                </button> */}

                                {/* <button className="gsi-material-button flex-grow-1" style={{width: "75%", margin: "auto"}} onClick={handleGoogleLogin}>
                                    <div className="gsi-material-button-state"></div>
                                    <div className="gsi-material-button-content-wrapper">
                                        <div className="gsi-material-button-icon">
                                            <img 
                                                src={`${djangoURL}/static/Templates/images/google.svg`} 
                                                alt="Google Logo" 
                                                style={{
                                                    width: "24px", 
                                                    height: "24px", 
                                                    marginRight: "10px"
                                                }}
                                            />
                                        </div>
                                        <span className="gsi-material-button-contents">Sign in</span>
                                        <span className="dnone">Sign in with Google</span>
                                    </div>
                                </button> */}
                            </FlexGroup>
                    <div id="footer"></div>
                    </FlexGroup>
                </div>
            </div>
            {/*         
            <div className='container padding1r card' style={{"margin": "auto", "margin-top":"10%"}}>
                <div className='padding1r'>
                    <h2>Login</h2>
                    <form onSubmit={onSubmitForm}>
                        <div className="mb-3">
                            <input type="username" placeholder='Username' autoComplete='off' className='form-control' id="username" onChange={onUsernameChange} />
                        </div>
                        <div className="mb-3">
                            <input type="password" placeholder='Password' autoComplete='off' className='form-control' id="password" onChange={onPasswordChange} />
                        </div>
                        <div className="mb-3">
                            <button disabled={loading} className='btn btn-success' type="submit">Login</button>
                        </div>
                    </form>
                </div>
            </div>
        // </div> */}

        </div>
    )
}
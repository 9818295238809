import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ContextMenu } from "primereact/contextmenu";
import { DataTable } from 'primereact/datatable';
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { isNull, isNullOrUndefined, isUndefined, modifyStateValue } from "../../functions/CoreFunctions";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { djangoURL } from "../../variables/CoreVariables";
import { AcclaimButton } from '../AcclaimButtons';
import FlexGroup from "../FlexGroup";
import { pdf, Document, Page, Text, View } from '@react-pdf/renderer';

const CRMDataTable = ({

    columns,
    apiLink,
    perm,
    editOptions,
    contextMenuTitlePrefix,
    contextMenuTitleKey,
    contextMenuItemLink,
    dataKey,
    onMiddleClick,
    onRowDoubleClick,
    globalFilterFields,
    children=null,
    selectionMode="single",
    selection,
    onSelectionChange,
    metaKeySelection=true,
    showTableElement=true,
    showTable=true,
    duplicate=()=>{},
    deleteItem=()=>{},
    tableRows=20,
    rowsPerPageOptions=[10,20,30],
    showContextMenu=true,
    onRowClick=() => {},
    manualData,

    sortField,
    sortOrder,
    overrideHeader
        
    
}) => {

    const {allPermissionsPresent } = useAuth()

    console.log(overrideHeader);
    

    const axiosPrivateInstance = useAxiosPrivate();
    const [loading, setLoading] = useState(true);
    const [filterString, setFilterString] = useState("");
    const [currentFilterName, setCurrentFilterName] = useState(null);
    const [items, setItems] = useState([
        {
            id: "",
            label: "ACCLAIM CRM",
            icon: "",
            url: "",
            // items: [],
            expanded: false,
            disabled: true,
            visible: true,
            target: null,
            separator: false,
            style: null,
            className: null,
            template: null,
            data: null,
        },
        { label: 'Open', icon: 'pi pi-copy' },
        { label: 'Open (New Tab)', icon: 'pi pi-copy' },
        { label: 'Duplicate', icon: 'pi pi-copy' },
        { label: 'Delete', icon: 'pi pi-copy' },
    ]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [TableData, setTableData] = useState([]);
    const [visibleColumns, setVisibleColumns] = useState(columns);
    const ctxtRef = useRef(null);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: tableRows,
        page: 0,
        sortField: null,
        sortOrder: null,
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const changeItem = (index, key, value) => {
        let temp = items;
        temp[index][key] = value;
        setItems(temp);
    }

    const loadLazyData = () => {
        console.log(lazyState);
        console.log(visibleColumns);
        console.log(filterString);
        console.log(globalFilterValue);
        console.log(TableData);
        if (showTable) {
            if (apiLink) {
                axiosPrivateInstance
                .get(`${apiLink}&fields=${visibleColumns.filter((v)=>{if (v.visibleByDefault || v.dataLoadedByDefault) {return true} return false}).map((v) => {return v.field}).join(",")}&start=${(lazyState.page)*lazyState.rows}&length=${lazyState.rows}${lazyState.sortField !== null ? `&orderBy=${lazyState.sortField}` : ""}${lazyState.sortOrder !== null ? (lazyState.sortOrder === 1 ? `&orderDir=desc` : '&orderDir=asc' ) : ""}${filterString}${globalFilterValue !== "" ? `&q=${globalFilterValue}` : ""}`)
                .then((result) => {
                    if (!isNullOrUndefined(TableData)) {
                        console.log("HERE 1");
                        setTableData(result.data.results);
                    }
                    console.log("HERE 2");
                    console.warn(result.data.results);
                    setTableData(result.data.results);
                    setTotalRecords(result.data.recordsTotal)
                    setTimeout(()=>setLoading(false),50);
                })
                .catch((err) => console.log(err));
            } else {
                // let sortedArray = [];
                // setTableData([]);
                // if (lazyState.sortField) {
                //     setTableData([]);
                //     sortedArray = TableData.sort((a, b) => {
                //         if (isNaN(a[lazyState.sortField])) {
                //             return lazyState.sortOrder * (a[lazyState.sortField].localeCompare(b[lazyState.sortField]));
                //         } else {
                //             return lazyState.sortOrder * (a[lazyState.sortField] - b[lazyState.sortField])
                //         }
                //     });

                //     sortedArray.splice(lazyState.first, (lazyState.first + lazyState.rows));
                //     console.log(sortedArray);
                //     sortedArray.splice(lazyState.rows);
                //     console.log(sortedArray);

                //     // setTableData(sortedArray);
                // } else {
                //     sortedArray = TableData;
                // }
                
                // // NEED TO DO PAGE TRYING THE BELOW CODE
                // // console.log(lazyState.first);
                // // console.log(lazyState.rows);
                // // let splicedArray = sortedArray.splice(lazyState.first, (lazyState.first + lazyState.rows));
                // // sortedArray.splice(lazyState.first, (lazyState.first + lazyState.rows));
                // // console.log(splicedArray);
                // // sortedArray.splice(lazyState.rows);
                // // console.log(sortedArray);

                // setTimeout(() => {
                //     setTableData(sortedArray);
                //     console.log("Waited for 1 second");
                // }, 100);

                setTableData(manualData);
                console.log(manualData)
                setTotalRecords(manualData.length)
                // setLoading(false);
                setLoading(false);
            }
        } else {
            setLoading(false);
        }
    };

    const onColumnToggle = (event) => {
        // console.log("===============================");
        // console.log("event.value");
        // console.log(event.value);
        // console.log("===============================");
        let selectedColumns = event.value;
        let orderedSelectedColumns = columns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));
        setVisibleColumns(orderedSelectedColumns);
    };

    useEffect(loadLazyData,[lazyState, visibleColumns, filterString, globalFilterValue, manualData]);

    const [ExcelData, setExcelData] = useState(null);

    useEffect(()=>{
        if (ExcelData) {
            import('xlsx').then((xlsx) => {
                const worksheet = xlsx.utils.json_to_sheet(ExcelData);
                const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
                const excelBuffer = xlsx.write(workbook, {
                    bookType: 'xlsx',
                    type: 'array'
                });

                saveAsExcelFile(excelBuffer, 'TableData');
            });

            setExcelData(null);
            
        }
    }, [ExcelData])
    if (perm) {
        if (!allPermissionsPresent(perm)) {
            return <div className="dnone"/>
        }
    }

    
    const exportExcel = () => {
        if (apiLink) {
            axiosPrivateInstance
            .get(`${apiLink}&fields=${visibleColumns.filter((v)=>{if (v.visibleByDefault || v.dataLoadedByDefault) {return true} return false}).map((v) => {return v.field}).join(",")}${lazyState.sortField !== null ? `&orderBy=${lazyState.sortField}` : ""}${lazyState.sortOrder !== null ? (lazyState.sortOrder === 1 ? `&orderDir=desc` : '&orderDir=asc' ) : ""}${filterString}${globalFilterValue !== "" ? `&q=${globalFilterValue}` : ""}`)
            .then((result) => {
                setExcelData(result.data.results);
            })
            .catch((err) => console.log(err));
        } else {
            setExcelData(TableData);
        }
    };
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const TablePDF = () => (
        <Document>
            <Page>
                <View style={{ padding:'10px'}}>
                <View style={{flexDirection:'row', marginTop:24}}/>

                    <View style={{ width:'100%', flexDirection :'row'}}>
                        {visibleColumns.map((column, index) => {
                            const specialStyle = {}
                            if (visibleColumns.length !== index + 1) {
                                specialStyle["borderRight"] = "0px";
                            }
                            return <View style={{width:'40%', paddingTop:4, paddingLeft:7, border:'1px solid black', borderBottom:"0px", ...specialStyle}}>
                                <Text style={{fontSize:"7pt"}}>{column["header"]}</Text>
                            </View>
                        })}
                    </View>
                    {TableData.map((row, index) => {
                        return <View style={{ width:'100%', flexDirection :'row'}}>
                            {visibleColumns.map((column, index2) => {
                                console.log(column["bodyTemplate"]);
                                const specialStyle = {}
                                if (TableData.length !== index + 1) {
                                    specialStyle["borderBottom"] = "0px";
                                }
                                if (visibleColumns.length !== index2 + 1) {
                                    specialStyle["borderRight"] = "0px";
                                }
                                return <View style={{width:'40%', paddingTop:4, paddingLeft:7, border:'1px solid black', ...specialStyle}}>
                                    <Text style={{fontSize:"7pt"}}>{column["bodyTemplate"] ? column["bodyTemplate"](row, column["field"]) : row[column["field"]]}</Text>
                                </View>
                            })}
                        </View>
                    })}
                </View>
                <View style={{ width:'100%', flexDirection :'column', pageBreakInside:"avoid"}}/>
            </Page>
        </Document>
    );

    const downloadPdf = async () => {
        const blob = await pdf(<TablePDF />).toBlob(); // Convert PDF to a Blob
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob); // Create a URL for the Blob
        link.download = 'Table.pdf'; // Specify the name of the downloaded file
        link.click(); // Trigger the download
    };
    
    const exportPdf = () => {
        console.log(visibleColumns);
        console.log(TableData);
        // return;

        downloadPdf();
    };
    // .dropdown a:hover {background-color: #ddd;}

    // .show {display: block;}

    // document.getElementById("myDropdown").classList.toggle("show");
    // const myRef = useRef(null);
    // useEffect(() => {
    //     myRef.current.addEventListener('click', handleClick);
    //     return () => {
    //         myRef.current.removeEventListener('click', handleClick);
    //     };
    // }, []);
    
    // const handleClick = (event) => {
    //     console.log('handleClick triggered!');
    //     console.log(event);
    //     console.log(event.target);
    // };
    // const [ShowDropdown, setShowDropdown] = useState(false);
    // const DropdownDownload = () => {return (<div style={{position:"relative", display:"inline-block"}}>
    //     <button ref={myRef} onClick={() => {setShowDropdown(!ShowDropdown)}} style={{backgroundColor:"#3498DB", color:"white", padding:"16px", fontSize:"16px", border:"none", cursor:"pointer"}}>Dropdown</button>
    //     {ShowDropdown && <div id="myDropdown" style={{position:"absolute", backgroundColor:"#f1f1f1", minWidth:"160px", overflow:"auto", boxShadow:"0px 8px 16px 0px rgba(0,0,0,0.2)", zIndex:"1"}}>
    //         <a style={{color:"black", padding:"12px 16px", textDecoration:"none", display:"block"}} onClick={exportExcel}>Excel</a>
    //         <a style={{color:"black", padding:"12px 16px", textDecoration:"none", display:"block"}} href="#about">PDF</a>
    //     </div>}
    // </div>)};

    const dropdownMenuItems = [
        {
            label: 'Options',
            items: [
                {
                    label: 'Excel',
                    icon: 'pi pi-refresh',
                    command: () => {
                        exportExcel();
                    }
                },
                {
                    label: 'PDF',
                    icon: 'pi pi-upload',
                    command: () => {
                        exportPdf();
                        console.log("handle PDF download");
                    }
                }
            ]
        }
    ];
    
    const tableHeader = isUndefined(overrideHeader) ? (<div className="width-100">
        <FlexGroup grow={true} className="width-100">
            <label className="padding1r m0">Show Columns: </label>
            <MultiSelect maxSelectedLabels={5} value={visibleColumns} options={columns} optionLabel="header" onChange={onColumnToggle} className="w-full sm:w-20rem" filter display="comma"/>{/*Chip*/}
            <AcclaimButton overrideWidth id="ChargerStocklistNewLineButton" className="p-ripple" onClick={exportExcel} items={dropdownMenuItems} style={{margin:"15px 0"}}>Download</AcclaimButton>
            {/* <DropdownDownload></DropdownDownload> */}
            {currentFilterName !== null && <AcclaimButton className="p-ripple" onClick={()=>{setFilterString(""); setCurrentFilterName(null);}} style={{margin:"15px 0"}}>Clear Filter</AcclaimButton>}
            <div style={{flexGrow:"1"}}></div>
            <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={(e) => setGlobalFilterValue(e.target.value)} placeholder="Keyword Search"/>
                    {/* <AcclaimTextBox fieldName="Job No." dataFieldName="JobNumber" data={ChargerData} setData={setChargerData}></AcclaimTextBox> */}

                </span>
            </div>
        </FlexGroup>
    </div>): isNull(overrideHeader) ? null : overrideHeader;

    const onPage = (event) => {
        if (loading) {return}
        // console.log("=======================");
        console.log("table page change");
        // console.log("event");
        // console.log(event);
        // console.log("=======================");
        setlazyState(event);
    };


    const headerTemplate = (data) => {
        return (
            <FlexGroup className="width-100 fr">
                {columns.map((v, index)=>{ return <span className="font-bold">{v["header"]}</span>})}
            </FlexGroup>
        );
    };
    
    const onSort = (event) => {
        if (loading) {return}
        console.log("sort");
        // console.log("event");
        // console.log(event);

        modifyStateValue(lazyState,setlazyState,(temp)=>{
            temp["sortField"] = event["sortField"];
            temp["sortOrder"] = event["sortOrder"];
            return temp;
        })


        // if (apiLink) {
        //     modifyStateValue(lazyState,setlazyState,(temp)=>{
        //         temp["sortField"] = event["sortField"];
        //         temp["sortOrder"] = event["sortOrder"];
        //         return temp;
        //     })
        // } else {
        //     if (event.sortField) {
                
        //         setTableData();
        //         let sortedArray = TableData.sort((a, b) => {
        //             if (isNaN(a[event.sortField])) {
        //                 // console.log(a);
        //                 // console.log(b);
        //                 // console.log(a[event.sortField]);
        //                 // console.log(b[event.sortField]);
        //                 // console.log(a[event.sortField].localeCompare(b[event.sortField]));
        //                 // return a[event.sortField].localeCompare(b[event.sortField]);
        //                 return event.sortOrder * (a[event.sortField].localeCompare(b[event.sortField]));
        //             } else {
        //                 //          1               *   
        //                 //          -1              *   
        //                 //          0               *   
        //                 return event.sortOrder * (a[event.sortField] - b[event.sortField])
        //             }
        //         });
        //         console.log("============================");
        //         console.log("sortedArray");
        //         console.log(sortedArray);
        //         console.log("============================");
        //         console.log("HERE 3");
        //         setTimeout(() => {
        //             setTableData(sortedArray);
        //             console.log("Waited for 1 second");
        //         }, 250);
        //     } else {
        //         console.log("HERE 4");
        //         setTableData(TableData);
        //     }
        // }

        // if (apiLink) {
        //     let temp = {...lazyState};
        //     temp["sortField"] = event["sortField"];
        //     temp["sortOrder"] = event["sortOrder"];
        //     setlazyState(temp);
        // } else {
        //     // let array = [
        //     //     { name: 'John', age: 30 },
        //     //     { name: 'Alice', age: 25 },
        //     //     { name: 'Bob', age: 35 }
        //     // ];
            
        //     // Sorting the array of dictionaries by age in ascending order
        //     // TableData.sort((a, b) => a.age - b.age);
            
        //     // console.log(array);
        //     // Output:
        //     // [
        //     //     { name: 'Alice', age: 25 },
        //     //     { name: 'John', age: 30 },
        //     //     { name: 'Bob', age: 35 }
        //     // ]
            
        //     // Sorting the array of dictionaries by name in alphabetical order
        //     console.log(TableData);
        //     if (event.sortField) {
        //         let sortedArray = TableData.sort((a, b) => {
        //             console.log(a);
        //             console.log(b);
        //             console.log(a[event.sortField]);
        //             console.log(b[event.sortField]);
        //             console.log(a[event.sortField].localeCompare(b[event.sortField]));
        //             // return a[event.sortField].localeCompare(b[event.sortField]);
        //             return event.sortOrder * (a[event.sortField].localeCompare(b[event.sortField]));
        //         });
        //         console.log("============================");
        //         console.log("sortedArray");
        //         console.log(sortedArray);
        //         console.log("============================");
        //         setTableData(sortedArray);
        //     } else {
        //         setTableData(TableData);
        //     }
            
            // console.log(array);
            // Output:
            // [
            //     { name: 'Alice', age: 25 },
            //     { name: 'Bob', age: 35 },
            //     { name: 'John', age: 30 }
            // ]
        // }
    };

    const onCellEditComplete = (e) => {
        let { rowData, newValue, field } = e;

        // console.log("e");
        // console.log(e);

        let dict = {};
        dict[field] = newValue !== null ? newValue : null;

        axiosPrivateInstance
        .patch(`${djangoURL}/api/salesPackTrucks/?filter=SalesPackTrucksID=${rowData.SalesPackTrucksID}`, dict)
        .then((res) => {
            rowData[field] = newValue !== "" ? newValue : null;
        })
        .catch((err) => console.log(err));
    };

    const cellEditor = (options) => {
        if (editOptions[options["field"]] === "number") {
            return numberEditor(options, "number");
        } else if (editOptions[options["field"]] === "date") {
            return dateEditor(options);
        } else if (editOptions[options["field"]] === "money") {
            return numberEditor(options, "money");
        } else {
            return textEditor(options);
        }
    };

    const textEditor = (options) => {
        return <input type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}/>;
    };

    const numberEditor = (options) => {
        return <input type="number" value={options.value} onChange={(e) => options.editorCallback(e.target.value)}/>;
    };

    const dateEditor = (options) => {
        return <Calendar value={(!isNullOrUndefined(options.value) ? moment(options.value).toDate() : null)} onChange={(e) => options.editorCallback(moment(e.target.value).format("YYYY-MM-DD"))}
            numberOfMonths={3} showButtonBar={true} showWeek={true} dateFormat="dd/mm/yy"
        />;
    };

    return (
        // <div style={{"width":"100%","height":"100vh","overflow":"hidden","display":"flex", flexGrow: 1}}>
        <div className="width-100 height-100 flex-grow-1" style={{overflow:"auto"}}>
            
            <ContextMenu model={items} ref={ctxtRef} breakpoint="767px" />
            {children}

            {/* <div>{filterString}</div> */}

            {/* <DataTable value={virtualCars} scrollable scrollHeight="400px"
                virtualScrollerOptions={{ lazy: true, onLazyLoad: loadCarsLazy, itemSize: 46, delay: 200, showLoader: true, loading: lazyLoading, loadingTemplate }} */}

                {(showTable && showTableElement) && <DataTable 
                    onContextMenu={(e) => {
                        if (showContextMenu) {
                            if (e.originalEvent.target.tagName === "TD") {
                                ctxtRef.current.show(e.originalEvent);
                                changeItem(0, "label", `${contextMenuTitlePrefix} (${e.data[contextMenuTitleKey]})`);
                                changeItem(1, "template", () => {return <Link className="p-menuitem-link" to={`/${contextMenuItemLink}/${e.data[dataKey]}`}><span class="p-menuitem-text" data-pc-section="label">Open</span></Link>});
                                changeItem(2, "template", () => {return <button className="p-menuitem-link" onClick={()=>window.open(`/v2/${contextMenuItemLink}/${e.data[dataKey]}`)}><span class="p-menuitem-text" data-pc-section="label">Open (New Tab)</span></button>});
                                changeItem(3, "template", () => {return <button className="p-menuitem-link" onClick={() => duplicate(e.data[dataKey])}><span class="p-menuitem-text" data-pc-section="label">Duplicate</span></button>});
                                changeItem(4, "template", () => {return <button className="p-menuitem-link" onClick={() => {deleteItem(e.data[dataKey]); ctxtRef.current.hide(e.originalEvent);}}><span class="p-menuitem-text" data-pc-section="label">Delete</span></button>});
                            }
                        }
                        // console.log(e);
                    }}
                    value={TableData}

                    // style={{ "width":'100%', flexGrow: 1 }}
                    style={{flexGrow:"1", overflow:"hidden", display:"flex", flexDirection:"column"}} // Chaned to allow overflow stretch to desired space

                    header={tableHeader}
                    // rowsPerPageOptions={[10,20,30]}
                    rowsPerPageOptions={rowsPerPageOptions}
                    stateStorage="local"
                    stateKey={contextMenuTitlePrefix}
                    dataKey={dataKey}
                    first={lazyState.first}
                    rows={lazyState.rows}
                    totalRecords={totalRecords}
                    onPage={onPage}
                    filters={lazyState.filters}
                    loading={loading}

                    currentPageReportTemplate={`Showing ${totalRecords === 0 ? 0 : lazyState.first + 1} to ${lazyState.first + lazyState.rows > totalRecords ? totalRecords : lazyState.first + lazyState.rows} of ${totalRecords} entries`}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"

                    selectionMode={selectionMode} // Changed to handle multiple selection modes pass through
                    selection={selection}
                    onSelectionChange={onSelectionChange}
                    metaKeySelection={metaKeySelection} // Changed to handle multiple selection modes pass through needed to be false for multiple selection
                    
                    onRowPointerDown ={(e) => {
                        // console.log(e);
                        // console.log(e.originalEvent.target.parentElement);
                        // console.log(e.originalEvent.button);
                        if (e.originalEvent.button === 1) { //Middle Button Clicked
                            e.originalEvent.preventDefault();
                            
                            onMiddleClick(e.data);
                        }
                        
                    }}
                    onRowDoubleClick={(e) => {onRowDoubleClick(e.data)}}
                    onRowClick={(e) => {onRowClick(e.data)}}
                    size="small"
                    groupRowsBy="MainAccount_id"
                    rowGroupHeaderTemplate={headerTemplate}
                    rowClassName={(e)=>{return e[Object.keys(e)[0]]}}
                    reorderableColumns
                    resizableColumns
                    showGridlines
                    stripedRows
                    pageLinkSize={5}
                    // removableSort // REMOVE SORTING ON THE THIRD CLICK
                    loadingIcon={<img alt="gg123" src="/v2/static/images/loading.gif" style={{maxWidth: "min(100px,max(15vw,15vh))", border: "1px solid grey", borderRadius: "99px"}}/>}
                    rowHover
                    sortMode="single"
                    paginator
                    lazy={apiLink}
                    // expandableRowGroups
                    // expandedRows={expandedRows}
                    // onRowToggle={(e) => setExpandedRows(e.data)}
                    // virtualScrollerOptions={{ lazy: false, onLazyLoad: loadCarsLazy, itemSize: 46, delay: 10, showLoader: true, loading: lazyLoading, loadingTemplate }}
                    onSort={onSort}
                    sortField={isNull(lazyState.sortField) ? sortField : lazyState.sortField}
                    sortOrder={isNull(lazyState.sortOrder) ? sortOrder : lazyState.sortOrder}
                    // onFilter={onFilter}
                    // selectAll={selectAll}
                    // onSelectAllChange={onSelectAllChange}>
                    // rowGroupMode="subheader"
                    // rowGroupFooterTemplate={footerTemplate} 
                    // removableSort // REMOVE SORTING ON THE THIRD CLICK
                    globalFilterFields={globalFilterFields}
                    // filters={filters}
                    // filterDisplay="row" // SETS THE FILTERS IN THE HEADER INSTEAD OF A MENU
                    // dataKey="HireContractID"
                    editMode="cell"
                    // paginator

                    
                    // onRowExpand={onRowExpand} 
                    // onRowCollapse={onRowCollapse} 
                    // rowExpansionTemplate={rowExpansionTemplate}
                    // expandedRows={expandedRows} 
                    // onRowToggle={(e) => setExpandedRows(e.data)}
                    
                    >
                    {visibleColumns.map(({ field, header, extraProps, allowEditing=false, sortable=true, filter=false, index, visibleByDefault=false, bodyTemplate }) => {
                        if (visibleByDefault) {
                            let elProps = {}
                            if (sortable) {
                                elProps["sortable"] = true;
                            }
                            if (filter) {
                                elProps["filter"] = true;
                            }
                            if (allowEditing) {
                                elProps["editor"] = (options) => cellEditor(options);
                                elProps["onCellEditComplete"] = onCellEditComplete;
                            }
                            return <Column key={field} field={field} header={header}
                                style={{ width:'25%' }}
                                body={bodyTemplate}
                                filterField={field} showFilterMenu={true} {...elProps} {...extraProps}></Column>;
                            }
                            return null;
                        
                        })
                        }
                </DataTable>}
        </div>
    );
}

export { CRMDataTable };
import axios from "axios"
import Cookies from "js-cookie"
import { isNullOrUndefined } from "../functions/CoreFunctions"
import { DEV_MODE, djangoURL, localAPIData } from "../variables/CoreVariables"
const API_URL = `${djangoURL}/api/`

const axiosInstance = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
        "Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,

        // 'Access-Control-Allow-Origin': '*', 
        "Content-Type": "application/json",
        // "credentials": 'include',
        "X-CSRFToken": Cookies.get('csrftoken'),
        // "Access-Control-Allow-Credentials": true
    }
})


class dummyThenCallback {
    constructor(callback, data) {
        if (!isNullOrUndefined(callback)) {
            // console.log(callback);
            callback(data);
        }
    }

    catch(callback) {
        // console.log(callback);
        callback({"message": []});
    }
}

class dummyResponse {
    data = [];
    constructor(responseData) {
        this.data = responseData;
        // return responseData;
    }

    then(callback) {
        return new dummyThenCallback(callback, this.data);
    }
}

class dummyAxiosPrivateInstance {
    
    data = [];

    delete(url) {
        console.log(`DELETE REQUEST @ ${url}`);
        return this.get(url);
    }

    post(url) {
        console.log(`POST REQUEST @ ${url}`);
        return this.get(url);
    }
    patch(url) {
        console.log(`PATCH REQUEST @ ${url}`);
        return this.get(url);
    }

    get(url) {
        let SingleItem = false;
        let wrap = false;
        // console.log("Test Get!");
        console.log(url);
        let splitURL = url.split("?")
        let baseURL = splitURL[0];
        let tempData = [];
        // console.log(localAPIData[baseURL]);
        if (!(localAPIData[baseURL] instanceof Array)) {
            if (Object.keys(localAPIData[baseURL]).includes("results")) {
                // console.log(localAPIData[baseURL]);
                tempData = [...localAPIData[baseURL]["results"]];
            } else {
                tempData = {...localAPIData[baseURL]};
            }
        } else {
            tempData = [...localAPIData[baseURL]];
        }
        let params = []
        if (splitURL.length > 1) {
            params = splitURL[1].split("&");
        }

        params.map((line)=>{
            let param = line.split("="); //["filter", "isNotification", "false,FromUser_id", "null"]
            let paramName = param[0].split("=")[0];
            if (paramName === "filter") {
                let filter = line.split("filter=")[1].split("&")[0].split(",");
                filter.map((filterLine)=>{
                    let field = filterLine.split("=")[0];
                    let filterData = filterLine.split("=")[1];
                    tempData = tempData.filter((dataLine)=>{
                        if (dataLine[field] == filterData) {
                            return dataLine;
                        }
                    })
                })
                this.data = tempData;
            }
            if (paramName === "showCount" || paramName === "select2") {
                wrap = true;
            }
            
            if (paramName === "returnSingleItem") {
                SingleItem = true;
            }
        })

        if (SingleItem) {
            tempData = tempData[0];
        } else if (wrap) {
            tempData = {"recordsTotal":tempData.lenth,"recordsFiltered":tempData.lenth,"results": tempData};
        }
        
        // if (!(localAPIData[baseURL] instanceof Array)) {
        //     if (Object.keys(localAPIData[baseURL]).includes("results")) {
        //         return new dummyResponse({data:{
        //             recordsTotal:tempData.length,
        //             recordsFiltered:tempData.length,
        //             "results":tempData
        //         }});
        //     }
        // }
        return new dummyResponse({data:tempData});
    }

    constructor(height, width) {
        this.height = height;
        this.width = width;
    }
}

// (new dummyAxiosPrivateInstance).get()

const axiosPrivateInstance =  DEV_MODE ? (new dummyAxiosPrivateInstance()) : axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
        // 'Access-Control-Allow-Origin': '*', 
        "Content-Type": "application/json",
        // "credentials": 'include',
        "X-CSRFToken": Cookies.get('csrftoken'),
        // "Access-Control-Allow-Credentials": true
    }
})

export { axiosInstance, axiosPrivateInstance }


// ["LOG", "DtTracker:Created a Enquiry for " + Customer]
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { AcclaimIcon } from "./AcclaimIcon";
import { isNullOrUndefined } from "../functions/CoreFunctions";
import { Menu } from "primereact/menu";

const AcclaimButton = (props) => {
  const navigate = useNavigate();

  const { 
      children="",
      id="",
      disabled=false,
      link="",
      newTab=false,
      className="",
      onFocus=(() => {}),
      onFocusOut=(() => {}),
      onMouseEnter=(() => {}),
      onMouseLeave=(() => {}),
      onClick=(() => {}),
      items=[],
      buttonType="",
      grow=false,
      overrideWidth=false,
      selected=false,
  } = props;

  const buttonRef = useRef(null); // Create a ref
  const [buttonWidth, setButtonWidth] = useState(0); // State to store the width
  const menuLeft = useRef(null);
  const {perms, allPermissionsPresent } = useAuth()

  useEffect(() => {
    // Check if the ref is assigned to the button and get its width
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth); // Access the width using offsetWidth
    }
  }, []); // Run only once after the component mounts

  if (props.perm) {
      if (!allPermissionsPresent(props.perm)) {
          return <div className="dnone"/>
      }
  }

  const handleMouseDown = (event) => {
    // Check if it's a middle-click (event.button === 1)
    if (event.button === 1) {
      window.open(`/v2${link}`, '_blank');
    }
  };
  
  const handleClick = (event) => {
    if (items.length == 0) {
      onClick();
      if (newTab) {
        if (link.includes("://")) {
          window.open(link);
        } else {
          window.open(`/v2${link}`);
        }
      } else {
        navigate(link);
      }
    } else {
      menuLeft.current.toggle(event);
    }
  }

  return (
      <div style={{...props.style}} className={`${className} ${grow ? "flex-grow-1" : ""} ${!overrideWidth && !grow ? "width-100" : ""}`}>
        <Menu model={items} popup ref={menuLeft} id="popup_menu_left" style={{width:`${buttonWidth}px`}}/>
        <button 
          ref={buttonRef}
          style={{height: "100%", maxHeight: "max(5vh,5vw)", ...(disabled === "disabled" || disabled === true ? {pointerEvents: "none"} : null)}} 
          className={(`btn btn-${(buttonType === "") ? "primary" : buttonType} width-100 pmd-ripple-effect linksButton`)}
          disabled={(disabled)}
          id={id}
          {...(disabled === true ? {} : {"onClick": handleClick})}
          onFocus={(e) => {onFocus(e)}}
          onMouseEnter={(e) => {onMouseEnter(e)}}
          onMouseLeave={(e) => {onMouseLeave(e)}}
          onBlur={(e) => {onFocusOut(e)}}
          onMouseDown={handleMouseDown}
          >
            {(children !== "") ? children : ""}
          </button>
      </div>
  );
}

const AcclaimIconButton = (props) => {
  var { 
      children="",
      id="",
      disabled=false,
      className="",
      onFocus=(() => {}),
      onFocusOut=(() => {}),
      onMouseEnter=(() => {}),
      onMouseLeave=(() => {}),
      onClick=(() => {}),
      buttonType="",
      link="",
      newTab=false,
      text=null,
      icon="",
      title="",
      style={}
  } = props;

  var buttonProps = {
    children: children,
    id: id,
    disabled: disabled,
    className: `AcclaimIconButton${(className === "" ? "" : " ")}${className}`,
    onFocus: onFocus,
    onFocusOut: onFocusOut,
    ...(disabled === true ? {} : {onClick: onClick}),
    buttonType: buttonType,
    style: style,
    onMouseEnter: onMouseEnter,
    onMouseLeave: onMouseLeave,
    link: link,
    newTab: newTab
  }

  var iconProps = {
    icon: icon,
    text: text,
    title: title
  }
  
  return (
    <AcclaimButton {...buttonProps}>
      <AcclaimIcon {...iconProps}>{children}</AcclaimIcon>
    </AcclaimButton>
  );
}

const AcclaimTabButton = (props) => {
  return (
    <span id={props.id} className={"d-flex tabactive " + props.className} onClick={props.onClick}>
      <span style={{"justify-content": "center", alignItems:"center", "display": "flex", "padding-left": "0.5vw", "margin-right": "0.5vw", "border-right": "1px solid grey", "margin-top": "1.5vh", "margin-bottom": "1.5vh"}}></span>
      <p style={{"margin":"auto", "padding-right": "1vw"}} className="tabButton d-flex">{props.children}</p>
    </span>
  );
}
export { AcclaimButton, AcclaimIconButton, AcclaimTabButton };


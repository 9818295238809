
import { useEffect, useState } from "react";
import FlexGroup from "../../../components/FlexGroup";
import { CRMDataTable } from "../../../components/CRM/CRMDataTable";
import { AcclaimButton } from "../../../components/AcclaimButtons";
import AcclaimModal from "../../../components/AcclaimModal";
import { djangoURL, websocketsURL } from "../../../variables/CoreVariables";
import moment from "moment";
import { axiosPrivateInstance } from "../../../axios";
import { openWebSocketConnection } from "../../../functions/Websockets";
import useWebSockets from "../../../hooks/useWebSockets";

const ManageServer = (props) => {
    const { ServerData, setServerData, handleGoBack } = props;

    console.log(props);

    const ManageServerHeader = <div class="user-status">
        <span class={`dot ${ServerData.Status}`}></span>
        <span>{ServerData.Customer} - {ServerData.Address}</span>
    </div>

    // `${ServerData.Customer} - ${ServerData.Address}`

    const [ShowRestoreBackUp, setShowRestoreBackUp] = useState(false);
    
    return <div>
        <AcclaimModal visible={true} header={ManageServerHeader} modal footer="" onHide={()=>{handleGoBack()}}>
            {Object.keys(ServerData).map((key) => {
                return <FlexGroup>
                    <div style={{width:"100%"}}>{key}</div>
                    <div style={{width:"100%"}}>{["LastUpdated"].includes(key) ? moment(ServerData[key]).format("DD/MM/YYYY HH:mm") : ServerData[key]}</div>
                </FlexGroup>
            })}
            <FlexGroup>
                <AcclaimButton onClick="">Updates</AcclaimButton>
                <AcclaimButton onClick={() => setShowRestoreBackUp(true)}>Restore Back Up</AcclaimButton>
            </FlexGroup>

            
            {ShowRestoreBackUp && <RestoreBackUp ServerData={ServerData} setServerData="" handleGoBack={() => setShowRestoreBackUp(false)}>
            </RestoreBackUp>}
        </AcclaimModal>
    </div>
}

const RestoreBackUp = (props) => {
    const { ServerData, setServerData, handleGoBack } = props;

    // console.log(props);
    const [ShowTable, setShowTable] = useState(true);
    const [SelectedRows, setSelectedRows] = useState(null);
    const [TableData, setTableData] = useState([]);

    
    useEffect(() => {
        axiosPrivateInstance.get(`${djangoURL}/api/backups/`)
        .then(response => {
            setTableData(response.data.backups);
        })
        .catch(error => console.error(`Error - ${error}`));
    }, []);

    const Data = [
        {
            "file_name": "2024/09/postgres_backup_2024-09-02_02-00-35.sql.gz",
            "size": 128758,
            "last_modified": "2024-09-02T02:00:37.251000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-03_02-00-27.sql.gz",
            "size": 128755,
            "last_modified": "2024-09-03T02:00:29.570000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-03_07-56-29.sql.gz",
            "size": 128753,
            "last_modified": "2024-09-03T07:56:34.561000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-04_02-00-51.sql.gz",
            "size": 139312,
            "last_modified": "2024-09-04T02:00:55.474000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-05_02-00-39.sql.gz",
            "size": 143622,
            "last_modified": "2024-09-05T02:00:42.025000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-06_02-00-41.sql.gz",
            "size": 137841,
            "last_modified": "2024-09-06T02:00:44.570000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-07_02-00-34.sql.gz",
            "size": 137998,
            "last_modified": "2024-09-07T02:00:37.033000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-08_02-00-43.sql.gz",
            "size": 140045,
            "last_modified": "2024-09-08T02:00:46.831000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-09_02-00-35.sql.gz",
            "size": 140912,
            "last_modified": "2024-09-09T02:00:38.059000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-10_02-00-40.sql.gz",
            "size": 314,
            "last_modified": "2024-09-10T02:00:41.879000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-11_02-00-40.sql.gz",
            "size": 140531,
            "last_modified": "2024-09-11T02:00:42.794000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-12_02-00-31.sql.gz",
            "size": 141282,
            "last_modified": "2024-09-12T02:00:33.852000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-13_02-00-29.sql.gz",
            "size": 142290,
            "last_modified": "2024-09-13T02:00:32.596000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-14_02-00-26.sql.gz",
            "size": 142811,
            "last_modified": "2024-09-14T02:00:28.059000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-15_02-00-32.sql.gz",
            "size": 143528,
            "last_modified": "2024-09-15T02:00:35.039000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-16_02-00-31.sql.gz",
            "size": 143922,
            "last_modified": "2024-09-16T02:00:33.734000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-17_02-00-30.sql.gz",
            "size": 145031,
            "last_modified": "2024-09-17T02:00:32.452000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-18_02-00-28.sql.gz",
            "size": 147871,
            "last_modified": "2024-09-18T02:00:30.730000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-19_02-00-27.sql.gz",
            "size": 137585,
            "last_modified": "2024-09-19T02:00:29.404000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-20_02-00-23.sql.gz",
            "size": 133883,
            "last_modified": "2024-09-20T02:00:25.657000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-21_02-00-28.sql.gz",
            "size": 140255,
            "last_modified": "2024-09-21T02:00:30.707000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-22_02-00-22.sql.gz",
            "size": 140255,
            "last_modified": "2024-09-22T02:00:24.369000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-23_02-00-22.sql.gz",
            "size": 141125,
            "last_modified": "2024-09-23T02:00:24.345000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-24_02-00-24.sql.gz",
            "size": 140587,
            "last_modified": "2024-09-24T02:00:25.779000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-25_02-00-25.sql.gz",
            "size": 149085,
            "last_modified": "2024-09-25T02:00:27.996000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-26_02-00-25.sql.gz",
            "size": 138303,
            "last_modified": "2024-09-26T02:00:28.017000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-27_02-00-23.sql.gz",
            "size": 139407,
            "last_modified": "2024-09-27T02:00:25.630000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-28_02-00-30.sql.gz",
            "size": 143678,
            "last_modified": "2024-09-28T02:00:32.315000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-29_02-00-23.sql.gz",
            "size": 143998,
            "last_modified": "2024-09-29T02:00:24.711000+00:00"
        },
        {
            "file_name": "2024/09/postgres_backup_2024-09-30_02-00-25.sql.gz",
            "size": 144541,
            "last_modified": "2024-09-30T02:00:27.433000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-01_02-00-23.sql.gz",
            "size": 154576,
            "last_modified": "2024-10-01T02:00:25.290000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-02_02-00-23.sql.gz",
            "size": 155644,
            "last_modified": "2024-10-02T02:00:25.599000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-03_02-00-21.sql.gz",
            "size": 156365,
            "last_modified": "2024-10-03T02:00:23.996000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-04_02-00-23.sql.gz",
            "size": 156734,
            "last_modified": "2024-10-04T02:00:25.027000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-05_02-00-27.sql.gz",
            "size": 156821,
            "last_modified": "2024-10-05T02:00:29.299000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-06_02-00-27.sql.gz",
            "size": 156913,
            "last_modified": "2024-10-06T02:00:29.518000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-07_02-00-24.sql.gz",
            "size": 156913,
            "last_modified": "2024-10-07T02:00:26.243000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-08_02-00-22.sql.gz",
            "size": 157103,
            "last_modified": "2024-10-08T02:00:24.534000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-09_02-00-30.sql.gz",
            "size": 255354,
            "last_modified": "2024-10-09T02:00:32.607000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-10_02-00-25.sql.gz",
            "size": 264312,
            "last_modified": "2024-10-10T02:00:27.168000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-11_02-00-26.sql.gz",
            "size": 265217,
            "last_modified": "2024-10-11T02:00:28.866000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-12_02-00-33.sql.gz",
            "size": 266467,
            "last_modified": "2024-10-12T02:00:35.897000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-13_02-00-27.sql.gz",
            "size": 272509,
            "last_modified": "2024-10-13T02:00:29.344000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-14_02-00-23.sql.gz",
            "size": 272509,
            "last_modified": "2024-10-14T02:00:25.579000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-15_02-00-26.sql.gz",
            "size": 274322,
            "last_modified": "2024-10-15T02:00:28.446000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-16_02-00-28.sql.gz",
            "size": 279639,
            "last_modified": "2024-10-16T02:00:30.439000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-17_02-00-29.sql.gz",
            "size": 280782,
            "last_modified": "2024-10-17T02:00:31.446000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-18_02-00-31.sql.gz",
            "size": 284512,
            "last_modified": "2024-10-18T02:00:33.586000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-19_02-00-25.sql.gz",
            "size": 284808,
            "last_modified": "2024-10-19T02:00:27.834000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-20_02-00-28.sql.gz",
            "size": 285605,
            "last_modified": "2024-10-20T02:00:30.465000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-21_02-00-36.sql.gz",
            "size": 285740,
            "last_modified": "2024-10-21T02:00:38.878000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-22_02-00-35.sql.gz",
            "size": 293991,
            "last_modified": "2024-10-22T02:00:40.841000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-23_02-00-34.sql.gz",
            "size": 293991,
            "last_modified": "2024-10-23T02:00:37.717000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-24_02-00-30.sql.gz",
            "size": 293991,
            "last_modified": "2024-10-24T02:00:33.892000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-25_02-00-46.sql.gz",
            "size": 293991,
            "last_modified": "2024-10-25T02:00:48.995000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-26_02-00-27.sql.gz",
            "size": 293991,
            "last_modified": "2024-10-26T02:00:29.238000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-27_02-00-27.sql.gz",
            "size": 293991,
            "last_modified": "2024-10-27T02:00:30.340000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-28_02-00-34.sql.gz",
            "size": 293991,
            "last_modified": "2024-10-28T02:00:40.014000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-29_02-00-34.sql.gz",
            "size": 293991,
            "last_modified": "2024-10-29T02:00:38.277000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-30_02-00-26.sql.gz",
            "size": 293991,
            "last_modified": "2024-10-30T02:00:29.823000+00:00"
        },
        {
            "file_name": "2024/10/postgres_backup_2024-10-31_02-00-30.sql.gz",
            "size": 293991,
            "last_modified": "2024-10-31T02:00:33.995000+00:00"
        },
        {
            "file_name": "2024/11/backup_2024-11-22_12-10-01.tar.gz",
            "size": 321575,
            "last_modified": "2024-11-22T12:10:04.501000+00:00"
        },
        {
            "file_name": "2024/11/backup_2024-11-23_02-00-30.tar.gz",
            "size": 321575,
            "last_modified": "2024-11-23T02:00:32.400000+00:00"
        },
        {
            "file_name": "2024/11/backup_2024-11-24_02-00-29.tar.gz",
            "size": 321689,
            "last_modified": "2024-11-24T02:00:32.121000+00:00"
        },
        {
            "file_name": "2024/11/backup_2024-11-25_02-00-29.tar.gz",
            "size": 321739,
            "last_modified": "2024-11-25T02:00:31.411000+00:00"
        }
    ]

    const { websocketSendMessage } = useWebSockets();

    const Restore = () => {
        console.log(`Restore ${SelectedRows.file_name} for ${ServerData.AppName}`);

        websocketSendMessage("restore", {
            type: "restore",
            app_name: ServerData.AppName,
            file_name: SelectedRows.file_name,
        });
    };
    const Delete = () => {
        console.log(`Delete ${SelectedRows.file_name} for ${ServerData.AppName}`);

        websocketSendMessage("restore", {
            type: "delete",
            app_name: ServerData.AppName,
            file_name: SelectedRows.file_name,
        });
    };
    
    return <div>
        <AcclaimModal visible={true} header="Restore Back Up" modal footer="" onHide={()=>{handleGoBack()}}>
            <CRMDataTable
                dataKey="file_name"
                // apiLink={`${djangoURL}/api/backups/?showCount=True&filter=app_name=b360-dev`}
                manualData={TableData}
                // setTableData={setTableData}

                sortField="last_modified"
                sortOrder={-1}

                overrideHeader={null}

                selectionMode="single"
                selection={SelectedRows}
                onSelectionChange={(e) => setSelectedRows(e.value)}

                metaKeySelection={false}

                showTable={ShowTable}
                onRowDoubleClick={(e) => {}}
                onMiddleClick={(e) => {}}
                contextMenuTitlePrefix="Charger"
                contextMenuTitleKey="Manufacturer"
                contextMenuItemLink=""
                globalFilterFields={["SpokeID"]}
                // COLUMNS DEFAULTS - visibleByDefault:false, sortable:true, filter:false, allowEditing:false
                columns={[
                    // {key:"file_name",       field:"file_name",      header:"file_name",     visibleByDefault:true, bodyTemplate:(rowData, field) => {return rowData["last_modified"] !== null ? moment(rowData["last_modified"]).format("DD/MM/YYYY HH:mm") : ""}},
                    {key:"last_modified",   field:"last_modified",  header:"Last Modified", visibleByDefault:true, bodyTemplate:(rowData, field) => {return rowData["last_modified"] !== null ? moment(rowData["last_modified"]).format("DD/MM/YYYY HH:mm") : ""}},
                    {key:"size",            field:"size",           header:"Size",          visibleByDefault:true, bodyTemplate:(rowData, field) => {return rowData["size"] !== null ? `${Math.round(rowData["size"]/1024*100)/100} KB` : ""}},
                ]}
            />
            
            <FlexGroup>
                <AcclaimButton disabled={SelectedRows === null} onClick={() => {Restore()}}>Restore</AcclaimButton>
                <AcclaimButton disabled={SelectedRows === null} onClick={() => {Delete()}}>Delete</AcclaimButton>
            </FlexGroup>
        </AcclaimModal>
    </div>
}

const AcclaimHomePage = (props) => {
    const [ShowManageServer, setShowManageServer] = useState(false);
    const [ServerData, setServerData] = useState(null);


    const [ShowTable, setShowTable] = useState(true);
    const [Data, setData] = useState([
        {
            "SpokeID": 4,
            "Status": "Online",
            "DNS": "192.168.16.225 ACC-WEB-002 192.168.16.225:8000",
            "IP": "192.168.16.225",
            "Port": null,
            "Customer": "Acclaim Handling Ltd",
            "LastBackedUp": null,
            "Version": "v0.0.8",
            "Users": 229,
            "Contact": "data@acclaimhandling.com",
            "ConnectedSince": "2024-11-21T15:16:02.763444",
            "LastUpdated": "2024-11-21T15:17:02.788244"
        },
        {
            "SpokeID": 3,
            "Status": "Online",
            "DNS": "('192.168.16.225 ACC-WEB-002 192.168.16.225:8000',)",
            "IP": "",
            "Port": "",
            "Customer": "Acclaim Handling Ltd",
            "LastBackedUp": null,
            "Version": "v0.0.8",
            "Users": 229,
            "Contact": "data@acclaimhandling.com",
            "ConnectedSince": "2024-11-21T13:27:44.263217",
            "LastUpdated": "2024-11-21T13:28:44.287459"
        }
    ]);

    return <div>
        {ShowManageServer && ServerData && <ManageServer ServerData={ServerData} setServerData="" handleGoBack={() => setShowManageServer(false)}>
        </ManageServer>}

        <CRMDataTable
            dataKey="Name" 
            apiLink={`${djangoURL}/api/spokes/?showCount=True`}
            // manualData={Data}
            // setTableData={setTableData}

            showTable={ShowTable}
            onRowDoubleClick={(e) => {setServerData(e); setShowManageServer(true);}}
            onMiddleClick={(e) => {}}
            contextMenuTitlePrefix="Charger"
            contextMenuTitleKey="Manufacturer"
            contextMenuItemLink=""
            globalFilterFields={["Status", "Name"]}
            // COLUMNS DEFAULTS - visibleByDefault:false, sortable:true, filter:false, allowEditing:false
            columns={[
                {key:"SpokeID",         field:"SpokeID",        header:"SpokeID",           visibleByDefault:true},
                {key:"Status",  field:"Status", header:"Status",    visibleByDefault:true, bodyTemplate:(rowData, field) => {
                    if (rowData["Status"] === "Online") {
                        return <div class="user-status" style={{display:"flex", alignItems:"center", fontSize:"14px", color:"#333"}}>
                            <span class="dot online" style={{width:"10px", height:"10px", marginRight:"8px", borderRadius:"50%", display:"inline-block", backgroundColor:"#28a745", boxShadow:"0 0 8px rgba(40, 167, 69, 0.5)"}}></span>
                            <span>Online</span>
                        </div>
                    } else {
                        return <div class="user-status" style={{display:"flex", alignItems:"center", fontSize:"14px", color:"#333"}}>
                            <span class="dot offline" style={{width:"10px", height:"10px", marginRight:"8px", borderRadius:"50%", display:"inline-block", backgroundColor:"#dc3545", boxShadow:"0 0 8px rgba(220, 53, 69, 0.5)"}}></span>
                            <span>Offline</span>
                        </div>
                    }
                }},
                {key:"DNS",             field:"DNS",            header:"DNS",               visibleByDefault:true},
                {key:"IP",              field:"IP",             header:"IP",                visibleByDefault:true},
                {key:"Port",            field:"Port",           header:"Port",              visibleByDefault:true},
                {key:"AppName",         field:"AppName",        header:"AppName",           visibleByDefault:true},
                {key:"Customer",        field:"Customer",       header:"Customer",          visibleByDefault:true},
                {key:"LastBackedUp",    field:"LastBackedUp",   header:"LastBackedUp",      visibleByDefault:true},
                {key:"Version",         field:"Version",        header:"Version",           visibleByDefault:true},
                {key:"Users",           field:"Users",          header:"Users",             visibleByDefault:true},
                {key:"Contact",         field:"Contact",        header:"Contact",           visibleByDefault:true},
                {key:"ConnectedSince",  field:"ConnectedSince", header:"ConnectedSince",    visibleByDefault:true, bodyTemplate:(rowData, field) => {return rowData["ConnectedSince"] !== null ? moment(rowData["ConnectedSince"]).format("DD/MM/YYYY HH:mm") : ""}},
                {key:"LastUpdated",     field:"LastUpdated",    header:"LastUpdated",       visibleByDefault:true, bodyTemplate:(rowData, field) => {return rowData["LastUpdated"] !== null ? moment(rowData["LastUpdated"]).format("DD/MM/YYYY HH:mm") : ""}},
                {key:"Contact",         field:"Contact",        header:"",                  sortable:false, visibleByDefault:true, bodyTemplate:(rowData, field) => {
                    return <AcclaimButton onClick={()=>{setServerData(rowData); setShowManageServer(true);}}>Manage</AcclaimButton>
                }},
            ]}
        />

    </div>
}
export default AcclaimHomePage;

// import React, { useEffect, useState } from 'react';
// import { Calendar } from 'primereact/calendar';
// import 'primereact/resources/themes/saga-blue/theme.css';
// import 'primereact/resources/primereact.min.css';
// // import 'primeicons/primeicons.css';

// import AcclaimSelectBox from "../../../components/AcclaimSelectBox";
// import { modifyStateValue } from '../../../functions/CoreFunctions';

// const AcclaimHomePage = () => {
//     const [selectedDate, setSelectedDate] = useState(new Date()); // Track the selected date
//     const [MonthValue, setMonthValue] = useState({"MonthValue":10}); // Track the selected date's date
//     const [YearValue, setYearValue] = useState({"YearValue":2024}); // Track the selected date's date
//     const [forceRenderKey, setForceRenderKey] = useState(0); // Key to force re-rendering

//     const handleDateChange = (e) => {
//         console.log("Date Selected:", e.value); // Log for debugging
//         setSelectedDate(e.value); // Update state
//     };

//     const handleMonthChange = (e) => {
//         const newMonth = e.target.value; // Get the new year from the event

//         const updatedDate = new Date(selectedDate); // Clone the current date
//         updatedDate.setMonth(newMonth); // Update only the year
//         setSelectedDate(updatedDate); // Save the updated date
//     };

//     const handleMonthChange2 = (e) => {
//         console.log("handleMonthChange2 triggered");
//         // const newMonth = e.target.value; // Get the new year from the event

//         // const updatedDate = new Date(selectedDate); // Clone the current date
//         // updatedDate.setMonth(newMonth); // Update only the year
//         // setSelectedDate(updatedDate); // Save the updated date
//     };

//     const handleYearChange = (e) => {
//         // setMonthValue(2001);



//         console.log(e.target.value);
//         console.log("Year Changed:", e.target.value); // Log the year change
//         const newYear = e.target.value; // Get the new year from the event

//         const updatedDate = new Date(selectedDate); // Clone the current date
//         updatedDate.setFullYear(newYear); // Update only the year
//         setSelectedDate(updatedDate); // Save the updated date

//         // const updatedDate = selectedDate ? new Date(selectedDate) : new Date();
//         // updatedDate.setFullYear(e.value); // Update the year of the selected date
//         // setSelectedDate(updatedDate); // Update state
//     };

//     useEffect(() => {
//         console.log(selectedDate.getFullYear());
//         console.log(YearValue["YearValue"]);
//         if (selectedDate.getFullYear() !== YearValue["YearValue"]) {
//             modifyStateValue(YearValue, setYearValue, (temp) => {
//                 temp["YearValue"] = selectedDate.getFullYear();
//                 return temp;
//             })
//             setForceRenderKey((prevKey) => prevKey + 1); // Force re-render
//         }
//         if (selectedDate.getMonth() !== MonthValue["MonthValue"]) {
//             modifyStateValue(MonthValue, setMonthValue, (temp) => {
//                 temp["MonthValue"] = selectedDate.getMonth();
//                 return temp;
//             })
//             setForceRenderKey((prevKey) => prevKey + 1); // Force re-render
//         }
//     }, [selectedDate]);

//     return (
//         <div>
//             <h3>Select a Date</h3>
//             <Calendar
//                 key={forceRenderKey} // Use the key to force re-render
//                 value={selectedDate} // Bind selected date to the calendar
//                 onChange={handleDateChange} // Handle date selection changes
//                 // onYearChange={handleYearChange} // Handle year navigator changes
//                 yearRange="2000:2030" // Define the year range
//                 dateFormat="dd/mm/yy" // Format the displayed date
//                 placeholder="Select a date" // Placeholder text
//                 showIcon // Optional: Display calendar icon
//                 inline

//                 onMonthChange={(e)=>{handleMonthChange2(e)}} // Handle year navigator changes
//                 monthNavigator // Enable month navigation dropdown
//                 monthNavigatorTemplate={
//                 <AcclaimSelectBox data={MonthValue} setData={setMonthValue} dataFieldName="MonthValue" onChange={(e)=>{handleMonthChange(e)}}>
//                     <option value="0">January</option>
//                     <option value="1">February</option>
//                     <option value="2">March</option>
//                     <option value="3">April</option>
//                     <option value="4">May</option>
//                     <option value="5">June</option>
//                     <option value="6">July</option>
//                     <option value="7">August</option>
//                     <option value="8">September</option>
//                     <option value="9">October</option>
//                     <option value="10">November</option>
//                     <option value="11">December</option>
//                 </AcclaimSelectBox>
//                 }
//                 yearNavigator // Enable year navigation dropdown
//                 yearNavigatorTemplate={
//                 <AcclaimSelectBox data={YearValue} setData={setYearValue} dataFieldName="YearValue" onChange={(e)=>{handleYearChange(e)}}>
//                     <option value="2023">2023</option>
//                     <option value="2024">2024</option>
//                     <option value="2025">2025</option>
//                 </AcclaimSelectBox>
//                 }
//             />
//             <p>Selected Date: {selectedDate ? selectedDate.toDateString() : "None"}</p>
//         </div>
//     );
// };


// export default AcclaimHomePage;

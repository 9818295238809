import { createContext } from 'react';

export const AlertContext = createContext({
    floatingAlertMessage: null,
    showFloatingAlertMessage: () => { }
})

export function AlertContextProvider(props) {
    return <AlertContext.Provider>
        {props.children}
    </AlertContext.Provider>
}

export default AlertContext
import useAuth from "../hooks/useAuth";

const FlexGroup = (props) => {
    const {perms, allPermissionsPresent } = useAuth()

    if (props.perm) {
        if (!allPermissionsPresent(props.perm)) {
            return <div className="dnone"/>
        }
    }


    const {
        style=null,
        gap="10",
        className="",
        type="unset",
        onClick=()=>{},
        onFocus=()=>{},
        onBlur=()=>{},
        // disabled=()=>{},
        alignItems="",
        wrap=null,
        wrapMobile=null,
        id="",
        key="",
        draggable=false,
        onDragEnter=()=>{},
        onDragLeave=()=>{},
        onDragOver=()=>{},
        onDrop=()=>{},
        onDragStart=()=>{}

    } = props;

    const globalProps = {
        onClick:onClick,
        onFocus:onFocus,
        onBlur:onBlur,
        // disabled:disabled,
        id: id,
        ...(key !== "" ? {key: key} : null),
        draggable: draggable,
        onDragEnter: onDragEnter,
        onDragLeave: onDragLeave,
        onDragOver: onDragOver,
        onDrop: onDrop,
        onDragStart: onDragStart,
        className: `${className} ${wrap ? "FlexGroupWrap" : ""} ${wrapMobile ? "FlexGroupWrapMobile" : ""} FlexGroup`
    }

    return (
        <div 
            // className="d-flex"
            style={{
                ...style,
                flexDirection: type,
                display : "flex",
                columnGap: `${gap}px`,
                rowGap: `${gap}px`,
                ...(props.grow && {flexGrow: "1"}),
                // ...(wrap && {flexWrap: "wrap"}),
                justifyContent: props.justifyContent ? props.justifyContent : "space-evenly",
                alignItems: alignItems,
            }
            }
            {...globalProps}
        >
            {props.children}
        </div>
    );
};

export default FlexGroup;
// export { AcclaimNavGroup, AcclaimNavSeparator };
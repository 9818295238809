import FlexGroup from "../../../components/FlexGroup";

const AdminSettingsPage = (
    

) => {

    return (
        <div className="padding1r">
            
        <FlexGroup
            type="column"
            style={{maxHeight: "100vh", overflow: "hidden"}}
        >

            <h2>Migrations</h2>


        </FlexGroup>

</div>
    )

}

export default AdminSettingsPage;
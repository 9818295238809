import React, { useEffect } from "react";
import useAuth from "../hooks/useAuth";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useWebSockets from "../hooks/useWebSockets";
import { djangoURL } from "../variables/CoreVariables";
import { useNavigate } from "react-router-dom";

const AcclaimPageWrapper = (props) => {
    const axiosPrivateInstance = useAxiosPrivate();
    const { alerts, setAlerts, notifications, setNotifications } = useWebSockets();
    const { user, allPermissionsPresent } = useAuth();
    console.log(user);

    useEffect(()=>{
        axiosPrivateInstance
        .get(`${djangoURL}/api/alerts/?filter=ForUser_id=${user.id}`)
        .then((res) => {
            // console.log(res);
            setNotifications(res.data.filter((i)=>{if (i.isNotification === true) {return i}}));
            setAlerts(res.data.filter((i)=>{if (i.isNotification === false) {return i}}));
        })
        .catch((err) => console.log(err));
    },[])

    // if the context is null then the data have not been fetched yet
    if (props.perm) {
        if (!allPermissionsPresent(props.perm)) {
            console.log("You do not have permission to access this page.");
            return (
                <div>You do not have permission to access this page.</div>
            );
        }
    }
    return (<div>{props.children}</div>)
}

const AcclaimPage = (props) => {
    const { user } = useAuth();
    const navigate = useNavigate();
    useEffect(()=>{
        if (!user) {
            navigate("/login");
        }
        console.log(user);
    },[])
    console.log(user);
    console.log(!((user.id === 1 && user.full_name !== "Keian Dunnill") || user.username === "B360Guest"));
    return (
        user && !((user.id === 1 && user.full_name !== "Keian Dunnill") || user.username === "B360Guest") && <AcclaimPageWrapper {...props}>
            <div style={{width: "100vw", "height": "100vh", overflowX: "hidden", overflowY: "auto", display: "flex"}}>{props.children}</div>
        </AcclaimPageWrapper>
    );
}

export default AcclaimPage;
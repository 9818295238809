import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
// import useRefreshToken from '../coreHooks/useRefreshToken'

export default function PersistLogin() {

    // const refresh = useRefreshToken()
    const { accessToken, setUser, setProteanUser } = useAuth();
    // console.log(useAuth());
    const [loading, setLoading] = useState(true);
    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        // console.log("PERSIST LOGIN");
        // console.log(accessToken);
        let isMounted = true

        async function verifyUser() {
            try {
                // await refresh()
                let _user = (await axiosPrivate.get('u')).data;
                console.log(_user);
                if ((!_user.id) || (_user.id && _user.id === 1 && _user.full_name !== "Keian Dunnill")) {
                    window.location.replace("https://www.google.com");
                    return;
                }
                if (_user.type === "full") {
                    let _protean_user = (await axiosPrivate.get('pu')).data;
                    setUser(_user);
                    setProteanUser(_protean_user);
                }
            } catch (error) {
                console.log(error.message)
            } finally {
                console.log("USER VERIFIED");
                isMounted && setLoading(false)
            }
        }

        !accessToken ? verifyUser() : setLoading(false)

        return () => {
            isMounted = false
        }
    }, [])

    return (
        loading ? "Loading" : <Outlet />
    )
}
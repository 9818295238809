import moment from "moment";
import { useParams } from "react-router-dom";

var paramstring = "";

const formatter = (value) => {
  if (!isNaN(value) && value !== null) {
    if (parseFloat(value) >= 0) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP'
        
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      }).format(value)
    } else {
      return `(${new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'GBP'
        
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      }).format(parseFloat(value) * -1)})`
    }
  } else {
    return value;
  }
};
//formatter(2500); /* £2,500.00 */

const generateRandomString = () =>
  [...crypto.getRandomValues(new Uint32Array(4))]
    .map(n => n.toString(36))
    .join('') + Date.now().toString(36);

const CalculateDowntime = (start, end) => {
  console.log("CalculateDowntime");

  // return datetime2.diff(datetime1, 'hours', true).toFixed(2)

  const regularStartHour = 0;
  const regularEndHour = 24;
  const fridayEndHour = 17; // 5:00 PM on Fridays
  const mondayStartHour = 8; // 8:00 AM on Mondays

  let current = moment(start); // Initialize current datetime to start datetime
  let businessHours = 0; // Initialize total business hours accumulator

  // Iterate through each day between start and end datetimes
  while (current.isBefore(end)) {
      if (current.isoWeekday() < 6) { // Weekday (1 = Monday, ..., 5 = Friday)
          let startOfBusinessDay, endOfBusinessDay;

          // Set business hours depending on the day of the week
          if (current.isoWeekday() === 5) { // Friday
              startOfBusinessDay = current.clone().hour(regularStartHour).minute(0).second(0);
              endOfBusinessDay = current.clone().hour(fridayEndHour).minute(0).second(0);
          } else if (current.isoWeekday() === 1) { // Monday
              startOfBusinessDay = current.clone().hour(mondayStartHour).minute(0).second(0);
              endOfBusinessDay = current.clone().hour(regularEndHour).minute(0).second(0);
          } else { // Other weekdays
              startOfBusinessDay = current.clone().hour(regularStartHour).minute(0).second(0);
              endOfBusinessDay = current.clone().hour(regularEndHour).minute(0).second(0);
          }

          // If the current time is before the start of the business day, set it to the start
          if (current.isBefore(startOfBusinessDay)) {
              current = startOfBusinessDay;
          }

          // If the current time is after the end of the business day, move to the next day
          if (current.isAfter(endOfBusinessDay)) {
              current.add(1, 'day').startOf('day');
              continue; // Skip to the next iteration
          }

          // If the end datetime is before the end of the business day, adjust the end of the business day
          if (end.isBefore(endOfBusinessDay)) {
              endOfBusinessDay = end;
          }

          // Calculate the difference in hours for the current day and add to the total business hours
          businessHours += moment.min(endOfBusinessDay, end).diff(current, 'hours', true);
          // Move to the next second after the end of the business day to avoid double counting
          current = endOfBusinessDay.add(1, 'second');
      } else {
          // If the current day is a weekend, move to the next day
          current.add(1, 'day').startOf('day');
      }
  }

  // Return the total business hours formatted to 2 decimal places
  // return `From ${moment(start).format("MMM Do YY HH:mm")} To ${moment(end).format("MMM Do YY HH:mm")} - Downtime: ${businessHours.toFixed(2)} hours`;
  return businessHours.toFixed(2);
};

const isNull = (v) => {return (v === null)}
const isUndefined = (v) => {return (typeof v === "undefined")}
const isNullOrUndefined = (v) => {return (isUndefined(v) || isNull(v))}
const isBlank = (v) => {return (v === "")}

const microsoftDateToMoment = (v) => {
  // let unixTimestamp =  //as per the post above, convert Excel date to unix timestamp, assuming Mac/Windows Excel 2011 onwards
  return moment.unix((v - 25569)*86400); //Pass in unix timestamp instead of Excel date
}

const momentToMicrosoftDate = (v) => {
  let unixTimestamp = v.unix();
  return (unixTimestamp/86400) + 25569;
}

const truncate = (str, n, dots=true) => {
  if (dots) {
      return (str.length > n) ? str.substr(0, n-1) + '&hellip;' : str;
  } else {
      return str.substring(0,n);
  }
}

const setParam = (paramName, value) => {
    var queryParams = new URLSearchParams(window.location.search);
    queryParams.set(paramName, value);
    paramstring = queryParams.toString();
    if (!paramstring.toUpperCase().includes(paramName.toString().toUpperCase())) {
        paramstring = paramstring + "&" + paramName + "=" + value.toString();
    }
    // history.replaceState(null, null, "?" + paramstring);
}
const getParam = (paramName) => {
    var queryParams = new URLSearchParams(window.location.search);
    return (queryParams.get(paramName) || "");
}

const isMobile = {
  Android: function() {
      return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
      return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function() {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function() {
      return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
      return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: function() {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  }
};

const modifyStateValue = (data, setData, setFunction) => {setData(setFunction(Array.isArray(data) ? [...data] : {...data}))}

const withRouter = WrappedComponent => props => {
    const params = useParams();
    // etc... other react-router-dom v6 hooks
  
    return (
      <WrappedComponent
        {...props}
        params={params}
        // etc...
      />
    );
  };

  const createObjectURL = ( blob ) => {
    if (typeof blob === "undefined") {
        return
    }
    if ( window.webkitURL ) {
        return window.webkitURL.createObjectURL( blob );
    } else if ( window.URL && window.URL.createObjectURL ) {
        return window.URL.createObjectURL( blob );
    } else {
        return null;
    }
}



const generateFolderTree = (folderData, prefix="", showFiles=true) => {
  let tree = [];

  Object.keys(folderData).map((key) => {
      console.log(Object.keys(folderData[key]));
      let src = ""
      if (Object.keys(folderData[key]).length > 0) {
        let extension = folderData[key]["label"].split(".");
        if (folderData[key]["children"]) {
            src = "http://192.168.16.225:8000/static/Templates/icons/folder_black.svg";
        } else if (["jpg", "png", "svc", "jpeg"].includes(extension[extension.length - 1])) {
            src = "http://192.168.16.225:8000/static/Templates/icons/image_black.svg"
        } else if (["pdf", "txt", "ini", "py"].includes(extension[extension.length - 1])) {
            src = "http://192.168.16.225:8000/static/Templates/icons/description_black.svg"
        } else if (["mp4", "gif"].includes(extension[extension.length - 1])) {
            src = "http://192.168.16.225:8000/static/Templates/icons/movie_black.svg"
        } else if (["mp3"].includes(extension[extension.length - 1])) {
            src = "http://192.168.16.225:8000/static/Templates/icons/music_note_black.svg"
        }else {
            src = "http://192.168.16.225:8000/static/Templates/icons/question_mark_black.svg"
        }
        let leaf = false;
        if (folderData[key]["type"] === "file") {
            leaf = true;
        }
  
        if ((folderData[key]["type"] === "folder" && !showFiles) || showFiles) {
          const newNode = {
            key: `${prefix}${key}`,
            label: folderData[key]["label"],
            type: folderData[key]["type"],
            // data: `${prefix}${key}`,
            // icon: 'pi-file',
            icon: <img src={src} alt="file-icon" width="16" />,
            leaf: leaf,
  
            children: folderData[key]["children"] ? generateFolderTree(folderData[key]["children"], `${prefix}${key}/`, showFiles) : [],
          };
          tree.push(newNode)
        } 
      } else {
        tree.push({
          key: key,
          label: key,
          type: "folder",
          icon: <img src={src} alt="file-icon" width="16" />,
          leaf: false,
          children: [],
        })
      }
    })
  return tree;
};

export { generateFolderTree, CalculateDowntime, createObjectURL, generateRandomString, formatter, getParam, isBlank, isMobile, isNull, isNullOrUndefined, isUndefined, microsoftDateToMoment, modifyStateValue, momentToMicrosoftDate, setParam, truncate, withRouter };


// import 'bootstrap/dist/css/bootstrap.min.css';
import { Toast } from 'primereact/toast';
import { createContext, useEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuditLog } from './apps/ACCLAIM/pages/AuditLog';
import  AdminSettingsPage  from "./apps/ACCLAIM/pages/AdminSettingsPage";
import AcclaimPage from "./components/AcclaimPage";
import CRMPage from './components/CRM/CRMPage';
import ErrorBoundary from './components/ErrorBoundary';
import PersistLogin from "./components/PersistLogin";
import AlertContext from './context/AlertContext';
import AuthContext from "./context/AuthContext";
import WebSocketsContext from './context/WebSocketsContext';
import { modifyStateValue } from './functions/CoreFunctions';
import { openWebSocketConnection } from './functions/Websockets';
import Login from "./pages/Auth/Login";
import Logout from "./pages/Auth/Logout";
import { DEV_MODE, websocketsURL } from './variables/CoreVariables';
import AcclaimHomePage from './apps/ACCLAIM/pages/AcclaimWebsiteHomePage';


function App() {
  const [user, setUser] = useState("");
  const [proteanUser, setProteanUser] = useState("");
  const [alerts, setAlerts] = useState([]);

  const showFloatingAlertMessage = (severity, summary, detail) => {
    floatingAlertMessage.current.show({ severity: severity, summary: summary, detail: detail, sticky: true });
  }

  //WEBSOCKETS====================================================================================================================================================================================
  const [latestWebsocketMessage, setLatestWebsocketMessage] = useState({});
  const [websocketGroups, setWebsocketGroups] = useState(((user) ? ["global",`global_${user.id}`] : ["global"]));
  const [websocket, setWS] = useState();
  const [websocketOnMessageCallbacks, setWebsocketOnMessageCallbacks] = useState({});
  const [notifications, setNotifications] = useState({});


  const addCallback = (name, callback) => {
    let temp = {...websocketOnMessageCallbacks};
    temp[name] = callback;
    setWebsocketOnMessageCallbacks(temp);
  }

  const removeCallback = (name) => {
    let temp = {...websocketOnMessageCallbacks};
    delete temp[name];
    setWebsocketOnMessageCallbacks(temp);
  }

  const singlePermissionPresent = (permsToCheck) => {
    // console.log(permsToCheck);

    // if (user.is_superuser) {return true}
    if (typeof permsToCheck === 'string' || permsToCheck instanceof String) {
      if (user.permissions.includes(permsToCheck)) {
        return true;
      }
    } else {
      for (let i; i<permsToCheck.length; i++) {
        if (user.permissions.includes(permsToCheck[i])) {
          return true;
        }
      }
    }
    return false;
  }

  const allPermissionsPresent = (permsToCheck) => {
    // console.log(permsToCheck);

    if (user.is_superuser) {return true}

    if (typeof permsToCheck === 'string' || permsToCheck instanceof String) {
      if (!user.permissions.includes(permsToCheck)) {
        return false;
      }
    } else {
      for (let i; i<permsToCheck.length; i++) {
        if (!user.permissions.includes(permsToCheck[i])) {
          return false;
        }
      }
    }
    return true;
  }

  useEffect(() => {
      setWS(openWebSocketConnection(`${websocketsURL}/ws/client/`, "global", ["restore"], ()=>{
          console.warn("wsClose window location reload!");
          // window.location.reload(); // - HAD TO COMMENT AS WHEN TYPING FOR LONG PERIODS IT WAS CONSTANTLY REFRESHING PEOPLE PAGES.
      },(msgData)=>{
          setLatestWebsocketMessage(msgData);
      },()=>{}))
  }, []);



  //==============================================================================================================================================================================================

  const floatingAlertMessage = useRef(null);

  return (

    <ErrorBoundary fallback={
      <div style={{width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center"}}>
        <div style={{margin: "auto"}}>Something went wrong, please contact the <a href="mailto:keian.dunnill@acclaimhandling.com;charlie.mason@acclaimhandling.com">DEV team</a> for assistance.</div>
      </div>
    }>
      <AuthContext.Provider value={{
          "user":user, "perms": user ? user.permissions : [], "setUser":setUser,
          "proteanUser":proteanUser, "setProteanUser":setProteanUser,
          "singlePermissionPresent": singlePermissionPresent, "allPermissionsPresent": allPermissionsPresent
      }}>
        <AlertContext.Provider value={{
          floatingAlertMessage: floatingAlertMessage,
          showFloatingAlertMessage: showFloatingAlertMessage
        }}>
          <WebSocketsContext.Provider value={{
            websocket: websocket,
            websocketOnMessageCallbacks: DEV_MODE ? ()=>{} : websocketOnMessageCallbacks,
            setWebsocketOnMessageCallbacks: DEV_MODE ? ()=>{} : setWebsocketOnMessageCallbacks,
            addCallback: DEV_MODE ? ()=>{} : addCallback,
            removeCallback: DEV_MODE ? ()=>{} : removeCallback,
            websocketAddGroup: DEV_MODE ? ()=>{} : (name) => {
              if (!websocketGroups.includes(name)) {
                console.log(`websocketAddGroup ${name}`);
                setWebsocketGroups([...websocketGroups, name]);
                if (websocket) {
                  console.log(JSON.stringify({"type": "addGroup", "group": name}));
                  websocket.send(JSON.stringify({"type": "addGroup", "group": name}));
                }
              }
            },
            websocketGroups: websocketGroups,
            websocketSendMessage: DEV_MODE ? ()=>{} : (group, data) => {
              console.log("websocket send message");
              console.log(JSON.stringify({"group": group, ...data}));
              websocket.send(JSON.stringify({"group": group, ...data}));
            },
            websocketLog: DEV_MODE ? ()=>{} : (data) => {
              console.log("websocket Log");
              console.log(JSON.stringify({
                  "type": "log",
                  user_id: user.id,
                  LogUserAgent: navigator.userAgent,
                  LogLocation: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  LogBasePageURL: window.location.href,
                  LogAPIURL: window.location.pathname,
                  ...data
                }));
              websocket.send(JSON.stringify({
                  "type": "log",
                  user_id: user.id,
                  LogUserAgent: navigator.userAgent,
                  LogLocation: Intl.DateTimeFormat().resolvedOptions().timeZone,
                  LogBasePageURL: window.location.href,
                  LogAPIURL: window.location.pathname,
                  ...data
                }))
            },
            latestWebsocketMessage: latestWebsocketMessage,
            alerts: alerts,
            setAlerts: setAlerts,
            notifications: notifications,
            setNotifications: setNotifications
          }}>
                <BrowserRouter basename={'/v2'}>
                  <Routes>
                    <Route path="login" element={<Login/>} component={Login}/>
                    <Route path="logout" element={<Logout/>} component={Logout}/>
                    {<Route path='/' element={<PersistLogin />}>
                      <Route path="admin_settings" element={<AcclaimPage><AdminSettingsPage/></AcclaimPage>} component={AdminSettingsPage}/>
                      <Route path="home" element={<AcclaimHomePage/>} component={AcclaimHomePage}/>
                      <Route path="audit_log" element={<CRMPage><AuditLog/></CRMPage>} component={AuditLog}/>
                    </Route>}
                  </Routes>
              </BrowserRouter>
            </WebSocketsContext.Provider>
          <Toast ref={floatingAlertMessage} />
      </AlertContext.Provider>
    </AuthContext.Provider>
  </ErrorBoundary>
  );
}

// const root = createRoot(document.getElementById('root'));
// root.render(<App />);
export default App;
import { Avatar } from 'primereact/avatar';
import { Badge } from 'primereact/badge';
import { Menu } from 'primereact/menu';
import { Menubar } from 'primereact/menubar';
import React, { useEffect, useRef, useState } from 'react';
import { modifyStateValue } from '../../functions/CoreFunctions';
import useAlerts from '../../hooks/useAlerts';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useWebSockets from '../../hooks/useWebSockets';
import { djangoURL } from '../../variables/CoreVariables';
import { AcclaimIcon } from '../AcclaimIcon';
import FlexGroup from '../FlexGroup';


const AcclaimMenuBar = (props) => {


    const { user, perms, singlePermissionPresent } = useAuth();
    const { notifications, setNotifications, alerts, setAlerts } = useWebSockets();
    const axiosPrivateInstance = useAxiosPrivate();
    const {showFloatingAlertMessage} = useAlerts();
    const menuRight = useRef(null);
    const menuNotifications = useRef(null);
    const [notificationsMenu, setNotificationsMenu] = useState([]);
    const avatarItems = [
        {label: `Welcome ${user.first_name}!`, disabled: true},
        {label: 'Settings', disabled: true},
        {label: 'Change Password', url: `${djangoURL}/password_change`},
        {label: 'Logout', url: `/v2/logout`}
    ];
    
    const deleteNotification = (alertID) => {
        axiosPrivateInstance
        .delete(`${djangoURL}/api/alerts/?filter=AlertID=${alertID}`)
        .then((res) => {
            modifyStateValue(notifications,setNotifications, (temp)=>{
                let ind = temp.indexOf(temp.filter((item)=>{if (item.AlertID === alertID) {return item}})[0]);
                temp.splice(ind, ind+1);
                return temp
            })
        })
        .catch((err) => console.log(err));
    }

    const notificationTemplate = (data) => {
        return <FlexGroup>
            <a {...(data.URL ? {href:data.URL} : {})} class="padding1r" role="menuitem" tabindex="0" data-pc-section="action">
                <FlexGroup type="column" alignItems="left">
                    <h4 style={{color: "#f68b1f"}} className="m0">{data.Heading}</h4>
                    <span style={{color: "black"}} class="p-menuitem-text" data-pc-section="label">{data.Detail}</span>
                </FlexGroup>
            </a>
            <AcclaimIcon icon="delete_white" invert={true} onClick={()=>deleteNotification(data.AlertID)}/>
        </FlexGroup>
    }

    useEffect(()=>{
        if (!(notifications.constructor == Object)) {
            modifyStateValue(notificationsMenu,setNotificationsMenu,(temp)=>{
                // console.log(temp);
                let final = [
                    {template: <h4 className="padding1r" style={{textAlign:"center", borderBottom: "1px solid grey", margin: "0px"}}>Notifications</h4>},
                    ...notifications.map((i)=>{
                        return {...(i.URL ? {URL: i.URL} : {}), template: notificationTemplate, ...i}
                    })
                ];
                alerts.map((i)=>{
                    showFloatingAlertMessage(i.Severity,i.Heading,i.Detail);
                })
                // console.log(final);
                return final;
            })
        }
    },[notifications])

    const { propItems=[] } = props;

    const end = (
        <div className='d-flex' style={{minWidth: "2.7vw", marginRight: "5px"}}>
            <Menu model={notificationsMenu} popup ref={menuNotifications} id="popup_menu_right" popupAlignment="right" />
            <Avatar style={{marginRight: "5px"}} shape="circle" onClick={(event) => menuNotifications.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup>
                <i className="pi pi-calendar p-overlay-badge notificationsBadge" style={{ fontSize: '1rem' }}>
                    <AcclaimIcon invert={true} label="lool" icon="notification_white" aria-controls="popup_menu_right" aria-haspopup/>
                    <Badge value={notifications.length} severity="danger"/>
                </i>
            </Avatar>

            {/* <Avatar style={{filter: "invert(0.5)"}} image={`${window.location.protocol}//${window.location.host}/v2/static/icons/notification_white.svg`} shape="circle" onClick={(event) => menuNotifications.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup/> */}
            <Menu model={avatarItems} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
            <Avatar image={`${window.location.protocol}//${window.location.host}/v2/static/icon.jpg`} shape="circle" onClick={(event) => menuRight.current.toggle(event)} aria-controls="popup_menu_right" aria-haspopup/>
        </div>
    );


    const hasGroup = (group) => {
        if (user.groups.includes(group) || user.is_superuser) {
            return true;
        }
        return false;
    }
    const hasSalesArea = (salesArea) => {
        if (user.sales_areas.includes(salesArea) || user.is_superuser) {
            return true;
        }
        return false;
    }

    const items = [
        {
            label: 'Main Menu',
            items: [
                {label: "Home", url: `${djangoURL}/`},
                {visible: singlePermissionPresent("view_marketingmaterial"), label: "Order Marketing Material", url: `${djangoURL}/orderMarketingMaterial`},
                {visible: singlePermissionPresent("view_live_stocklist"), label: "Live Stocklist", items: [
                    {label: "Live Stocklist", url: `${djangoURL}/LSL`},
                    {visible: singlePermissionPresent("view_reserverequests"), label:"Stocklist Reserves", url:`${djangoURL}/reserves`},
                    {visible: singlePermissionPresent("view_live_stocklist"), label:"Lonking Stocklist", url:"/v2/supplier_stocklist"},
                    {label: "Charger Stocklist", url: "/v2/charger_stocklist"},
                    {visible: singlePermissionPresent("view_truck_query"), label: "Truck Query", url: `${djangoURL}/TruckQuery`},
                    {visible: singlePermissionPresent("view_truck_photos"), label: "Truck Photos", url: `${djangoURL}/Photos`},
                    {visible: singlePermissionPresent("approve_imageuploadrequests"), label: "Review Photo Uploads", url: `${djangoURL}/uploadRequests`},
                ]},
                {visible: singlePermissionPresent("view_truckpreparationpipeline"), label: "Truck Preps", url: `${djangoURL}/TruckPrepPipeline`},
                {visible: singlePermissionPresent("view_sfgsavesdata") || singlePermissionPresent("view_truckcomparison"), label: "Sales", items: [
                    {visible: singlePermissionPresent("view_sfgsavesdata"), label: "SFG", url: (hasGroup("SFGv2_users") ? "/v2/SFG" : `${djangoURL}/SFG`)},
                    {visible: singlePermissionPresent("view_sfgsavesdata"), label: "Used Quote Generator", url: `${djangoURL}/UQG`},
                    // {label: "Hyundai Quote Generator", url: `${djangoURL}/SFG`},
                    {visible: singlePermissionPresent("view_truckcomparison"), label: "Fuel Calculator", url: `${djangoURL}/fuelPriceCalculator`},
                    {visible: singlePermissionPresent("view_sfgsavesdata"), label: "Create Task", url: `${djangoURL}/createTaskPage`},
                    {visible: singlePermissionPresent("view_sfgsavesdata"), label: "My Cold Calls", url: `${djangoURL}/coldCall`},
                ]},
                {visible: singlePermissionPresent("view_vwmailshotmailerlist"), label: "Mailshot", items: [
                    {label: "Mailshot Lists", url: `${djangoURL}/MailshotLists`},
                    {label: "Mailshot Sent", url: `${djangoURL}/mailshotSent`},
                ]},
                // {visible: user.sales_areas.length > 0 || user.is_superuser, label: "Follow Ups", items: user.sales_areas.map((salesArea)=>{
                //     return {label: `Sales Area ${salesArea} Follow Ups`, url: `${djangoURL}/sales/followups?sa=${salesArea}`}
                // })},



                {visible: singlePermissionPresent("add_returns"), label: "Parts", items: [
                    {label: "Submit a Return", url: `${djangoURL}/Returns`},
                    {label: "Parts Bin Inventory", url: `${djangoURL}/PartsBinInventory`},
                ]},
                {visible: singlePermissionPresent("view_vwtorquewrenchregister"), label: "Torque Wrench Register", url: `${djangoURL}/TorqueWrenchRegister`},
                {visible: singlePermissionPresent("view_dttrackerdata"), label: "Training", items: [
                    {label: "DT Tracker", url: `${djangoURL}/dtTracker`},
                    {label: "DT Calendar", url: `${djangoURL}/dtTracker/calendar`},
                    {label: "Internal Training", url: `${djangoURL}/internalTraining`},
                ]},
                {visible: singlePermissionPresent("view_creditholds"), label: "Credit Holds", url: `${djangoURL}/creditHolds`},
                {visible: singlePermissionPresent("view_servicemap"), label: "Service Map", url: `${djangoURL}/serviceMap`},
                {visible: hasGroup("Marketing"), label: "Force Update Website", url: `https://acclaimhandling.co.uk/?update=update`},
                {visible: hasGroup("Marketing"), disabled: true, label: "Update Stocklist New Trucks"},
                {visible: hasGroup("IT"), label: "IT", items: [
                    {label: "Protean Kicker", url: `${djangoURL}/proteanSessions`},
                    {label: "Anydesk Addresses", url: `${djangoURL}/anyDesk`},
                    {label: "Reports - SSRS", url: "http://192.168.16.209/Reportman"},
                ]},
                {visible: singlePermissionPresent("view_log_entries") || singlePermissionPresent("view_pagestats_only_log_entries"), label: "Dev Tools", items: [
                    {visible: singlePermissionPresent("view_log_entries"), label: "View Log", url: `${djangoURL}/log`},
                    {visible: singlePermissionPresent("view_pagestats_only_log_entries"), label: "View Page Stats", url: `${djangoURL}/log`},
                    {visible: user.is_superuser, label: "Admin", url: `${djangoURL}/admin`},
                    {visible: user.is_superuser, label: "Telematics", url: `${djangoURL}/telematics/dashboard`},
                    {visible: user.is_superuser, disabled: true, label: "Update Quick Quote Price"},
                    {visible: user.is_superuser, disabled: true, label: "Update Lonking Price"},
                    {visible: user.is_superuser, label: "Template Page", url: `${djangoURL}/templatePage`},
                ]},
            ]
        },
        // [...propItems]
    ];

    return (
        <Menubar model={[...items, ...propItems]} end={end}  style={{zIndex:999999999999999999999999900999999}}/>
    )
}

export default AcclaimMenuBar;
function waitForSocketConnection(socket, callback) {
    setTimeout(
        function () {
            if (socket.readyState === 1) {
                console.log("Connection is made");
                if (callback !== null){
                    callback();
                }
            } else {
                console.log("wait for connection...");
                waitForSocketConnection(socket, callback);
            }

        }, 5); // wait 5 milisecond for the connection...
}
function openWebSocketConnection(url, connectionName, groups=[], onOpen=()=>{}, onMessage=()=>{}, onClose=()=>{}) {

    const websocket = new WebSocket(url);
        
    websocket.onopen = (event) => {
        console.log("connected");
        console.warn(groups);
        //OnOpen
        groups.map((group) => {
            console.log(JSON.stringify({"type": "addGroup", "group": group}));
            websocket.send(JSON.stringify({"type": "addGroup", "group": group}));
        })
        onOpen();
    };

    websocket.onmessage = (me) => {
        let msgData = JSON.parse(me.data);
        console.log(msgData);
        onMessage(msgData);
    };

    websocket.onclose = (CloseEvent) => {onClose(CloseEvent)};

    if (websocket.readyState === 1) {
    //if (bugReportsSocket.readyState === WebSocket.OPEN) {
        websocket.onopen();
    }

    websocket.onmessage = (MessageEvent) => {

        onMessage(JSON.parse(MessageEvent.data.toString()));
    };

    return websocket;
}
export { openWebSocketConnection, waitForSocketConnection };


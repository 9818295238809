import moment from "moment";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import React from "react";
import { CRMDataTable } from "../../../components/CRM/CRMDataTable";
import { djangoURL } from "../../../variables/CoreVariables";
import { useNavigate } from "react-router-dom";

import { CalculateDowntime } from "../../../functions/CoreFunctions";

const AuditLog = (props) => {
    const navigate = useNavigate();
    
    const tagAbbr = {
        "A": "Accounts",
        "C": "Customer",
        "E": "Equipment",
    }

    const checkTags = (dataString) => {
        let tags = dataString.split("|");
        let returnTags = [];
        for (let i=0; i<tags.length; i++) {
            let tagString = tags[i];
            let tag = tagString.split(":");
            console.log(tagString);
            console.log(tag[0]);
            let abbr = tagAbbr[tag[0]];
            let name = tag[tag.length-1];
            console.log(abbr);
            console.log(name);
            returnTags.push(<span>{abbr} {name}</span>);
        }
        return returnTags;
    }


    return (

        <CRMDataTable 
            dataKey="CustomerID" 
            apiLink={`${djangoURL}/api/audit_log_entries/?showCount=True&orderBy=OccurredAt&orderDir=asc`}
            showTable={true}
            onRowDoubleClick={(e) => navigate(`/crm/customers/${e.CustomerID}/details`)}
            onMiddleClick={(e) => {window.open(`/v2/crm/customers/${e.CustomerID}/details`)}}
            contextMenuTitlePrefix="Customer"
            contextMenuTitleKey="Name"
            contextMenuItemLink="crm/customers"
            globalFilterFields={[]}
            columns={[
                {key:"Date",        field:"OccurredAt", header:"Date", visibleByDefault:true, allowEditing:false, sortable:true, filter:false, bodyTemplate: (rowData, field)=>{return moment(rowData["OccurredAt"]).format("DD/MM/YY HH:mm:ss")}},
                {key:"Severity",        field:"Severity__Name", header:"Severity", visibleByDefault:true, allowEditing:false, sortable:true, filter:false},
                {key:"LogMessage",  field:"LogEntryID", header:"Log Message", visibleByDefault:true, allowEditing:false, sortable:true, filter:false, 
                bodyTemplate: (rowData, field)=>{
                    
                    let section = rowData["Section"].split("|");
                    section = section.length > 1 ? section[1] : section[0];
                    // let handleParent = rowData["ParentSectionType__Name"] !== null ? ` on "${rowData["ParentSection"]}" ${rowData["ParentSectionType__Name"]}` : "";
                    // let dataChange = rowData["OriginalData"] !== null ? ` from "${rowData["OriginalData"]}" to "${rowData["EditedData"]}"` : "";

                    return <div>
                        {(rowData["By_id"] !== null ? `${rowData["By__first_name"]} ${rowData["By__last_name"]} ` : "ANONYMOUS USER ")} 
                        {rowData["ActionType__Verb"]} the <b>{section} {rowData["SectionType__Name"]} </b>
                        {rowData["SpecificItemTags"] !== null ? "for " : ""}<b>{rowData["SpecificItemTags"] !== null ? checkTags(rowData["SpecificItemTags"]) : ""}</b>
                        {rowData["OriginalData"] !== null ? " from " : ""}<b>{rowData["OriginalData"] !== null ? rowData["OriginalData"] : ""}</b>{rowData["OriginalData"] !== null ? " to " : ""}<b>{rowData["EditedData"] !== null ? rowData["EditedData"] : ""}</b>
                        {rowData["ParentSectionType__Name"] !== null ? " on the " : ""}<b>{rowData["ParentSection"]} {rowData["ParentSectionType__Name"]} </b>
                        {rowData["Notes"]}
                        {rowData["EventStart"] !== null ? " from " : ""}<b>{rowData["EventStart"] !== null ? moment(rowData["EventStart"]).format("DD/MM/YY HH:mm") : ""}</b>{rowData["EventEnd"] !== null ? " to " : ""}<b>{rowData["EventEnd"] !== null ? moment(rowData["EventEnd"]).format("DD/MM/YY HH:mm") : ""} </b>
                        {rowData["EventStart"] !== null ? `Event Lasted: ${CalculateDowntime(moment(rowData["EventStart"]),moment(rowData["EventEnd"]))} Hours!` : ""}


                        {/* <b>{handleParent}</b> */}
                        {/* {dataChange} */}
                    </div>
                    }
                },


                {key:"1",  field:"By_id",                   header:"", visibleByDefault:false, dataLoadedByDefault:true, allowEditing:false, sortable:false, filter:false},
                {key:"2",  field:"By__first_name",          header:"", visibleByDefault:false, dataLoadedByDefault:true, allowEditing:false, sortable:false, filter:false},
                {key:"3",  field:"By__last_name",           header:"", visibleByDefault:false, dataLoadedByDefault:true, allowEditing:false, sortable:false, filter:false},
                {key:"4",  field:"SectionType__Name",       header:"", visibleByDefault:false, dataLoadedByDefault:true, allowEditing:false, sortable:false, filter:false},
                {key:"5",  field:"Section",                 header:"", visibleByDefault:false, dataLoadedByDefault:true, allowEditing:false, sortable:false, filter:false},
                {key:"6",  field:"ParentSectionType__Name", header:"", visibleByDefault:false, dataLoadedByDefault:true, allowEditing:false, sortable:false, filter:false},
                {key:"7",  field:"ParentSection",           header:"", visibleByDefault:false, dataLoadedByDefault:true, allowEditing:false, sortable:false, filter:false},
                {key:"8",  field:"ActionType__Verb",        header:"", visibleByDefault:false, dataLoadedByDefault:true, allowEditing:false, sortable:false, filter:false},
                {key:"9",  field:"SpecificItemTags",        header:"", visibleByDefault:false, dataLoadedByDefault:true, allowEditing:false, sortable:false, filter:false},
                {key:"10", field:"OriginalData",            header:"", visibleByDefault:false, dataLoadedByDefault:true, allowEditing:false, sortable:false, filter:false},
                {key:"11", field:"EditedData",              header:"", visibleByDefault:false, dataLoadedByDefault:true, allowEditing:false, sortable:false, filter:false},
                {key:"12", field:"Notes",                   header:"", visibleByDefault:false, dataLoadedByDefault:true, allowEditing:false, sortable:false, filter:false},
                {key:"13", field:"EventStart",              header:"", visibleByDefault:false, dataLoadedByDefault:true, allowEditing:false, sortable:true, filter:false},
                {key:"14", field:"EventEnd",                header:"", visibleByDefault:false, dataLoadedByDefault:true, allowEditing:false, sortable:true, filter:false},


                // {key:"LogEntryID", field:"LogEntryID", header:"LogEntryID", allowEditing: false, visibleByDefault: false, sortable: false, filter: false},
                // {key:"IP", field:"IP", header:"IP", allowEditing: false, visibleByDefault: false, sortable: false, filter: false},
                // {key:"UserAgent", field:"UserAgent", header:"UserAgent", allowEditing: false, visibleByDefault: false, sortable: false, filter: false},
                // {key:"Location", field:"Location", header:"Location", allowEditing: false, visibleByDefault: false, sortable: false, filter: false},
                // {key:"BasePageURL", field:"BasePageURL", header:"BasePageURL", allowEditing: false, visibleByDefault: false, sortable: false, filter: false},
                // {key:"APIURL", field:"APIURL", header:"APIURL", allowEditing: false, visibleByDefault: false, sortable: false, filter: false},
                // {key:"Severity_id", field:"Severity_id", header:"Severity_id", allowEditing: false, visibleByDefault: false, sortable: false, filter: false},
                // {key:"HTTPMethod_id", field:"HTTPMethod_id", header:"HTTPMethod_id", allowEditing: false, visibleByDefault: false, sortable: false, filter: false},
                // {key:"ActionType_id", field:"ActionType_id", header:"ActionType_id", allowEditing: false, visibleByDefault: false, sortable: false, filter: false},
                // {key:"ParentSection", field:"ParentSection", header:"ParentSection", allowEditing: false, visibleByDefault: false, sortable: false, filter: false},
                // {key:"ParentSectionType_id", field:"ParentSectionType_id", header:"ParentSectionType_id", allowEditing: false, visibleByDefault: false, sortable: false, filter: false},
                // {key:"Section", field:"Section", header:"Section", allowEditing: false, visibleByDefault: false, sortable: false, filter: false},
                // {key:"SectionType_id", field:"SectionType_id", header:"SectionType_id", allowEditing: false, visibleByDefault: false, sortable: false, filter: false},
                // {key:"Notes", field:"Notes", header:"Notes", allowEditing: false, visibleByDefault: false, sortable: false, filter: false},
                // {key:"DeleteAfter", field:"DeleteAfter", header:"DeleteAfter", allowEditing: false, visibleByDefault: false, sortable: false, filter: false},

            ]}
        >
            
        </CRMDataTable>
    );
}

export { AuditLog };


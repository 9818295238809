import { createContext } from 'react';

export const WebSocketContext = createContext({
    websocket:()=>{},
    websocketOnMessageCallbacks: {},
    setWebsocketOnMessageCallbacks: ()=>{},
    websocketAddGroup: ()=>{},
    websocketSendMessage: ()=>{},
    websocketLog: ()=>{},
    addCallback: ()=>{},
    removeCallback: ()=>{},
    latestWebsocketMessage: {},
    alerts: [],
    setAlerts: ()=>{},
    notifications: [],
    setNotifications: ()=>{}
})

export function WebSocketContextProvider(props) {
    return <WebSocketContext.Provider>
        {props.children}
    </WebSocketContext.Provider>
}

export default WebSocketContext
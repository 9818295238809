import React, { useEffect } from "react";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useWebSockets from "../../hooks/useWebSockets";
import { djangoURL } from "../../variables/CoreVariables";
import FlexGroup from "../FlexGroup";
import NoAccess from "../NoAccess";
import AcclaimMenuBar from "./AcclaimMenuBar";
import Cookies from "js-cookie";

const CRMPageWrapper = (props) => {
    
    const axiosPrivateInstance = useAxiosPrivate();
    const { setAlerts, setNotifications } = useWebSockets();
    const { user } = useAuth();
    // const { user, perms } = useAuth();

    useEffect(()=>{
        axiosPrivateInstance
        .get(`${djangoURL}/api/alerts/?filter=ForUser_id=${user.id}`)
        .then((res) => {
            console.log(res);
            setNotifications(res.data.filter((i)=>{if (i.isNotification === true) {return i} return false}));
            setAlerts(res.data.filter((i)=>{if (i.isNotification === false) {return i} return false}));
        })
        .catch((err) => console.log(err));
    },[])

    return (<div>{props.children}</div>)
}

const CRMPage = (props) => {

    const { user, allPermissionsPresent } = useAuth();

    const menuItems = [
        {label: "Dashboard❗", url: "/v2/crm"},
        {visible: allPermissionsPresent("view_customer_section"), label: "Customers❗", items: [
            {label: "Customers❗", items: [
                {label: "View❗", url: "/v2/crm/customers"},
                {visible: allPermissionsPresent("create_customer"), label: "Create ✅", url: "/v2/crm/customers/create"},
            ]},
            {label: "Contacts❗", items: [
                {label: "View❗", url: "/v2/crm/contacts"},
                {visible: allPermissionsPresent("create_contact"), label: "Create ✅", url: "/v2/crm/contacts/create"},
            ]},
            {visible:allPermissionsPresent("view_customer_section"), label:"Config ✅", items:[
                {visible: allPermissionsPresent("view_customer_section"), label: "Task Types ✅", url: "/v2/crm/history/types"},
            ]}
        ]},
        {visible: allPermissionsPresent("view_job_section"), label: "Jobs❗", items: [
            {label: "Jobs❗", items: [
                {label: "View❗", url: "/v2/crm/jobs"},
                {visible: allPermissionsPresent("create_job"), label: "Create❗", url: "/v2/crm/jobs/create"},
            ]},
            {visible:allPermissionsPresent("view_job_section"), label:"Config❗", items:[
                {visible: allPermissionsPresent("view_job_type"), disabled: true, label: "Types❗"},
                {visible:allPermissionsPresent("view_labour_profile"), label:"Labour Profiles ✅", url: "/v2/crm/service/labour/profiles"},
                {visible:allPermissionsPresent("view_service_type"), label:"Service Types ✅", url: "/v2/crm/service/types"},
                {visible: allPermissionsPresent("view_job_inspection_section"), disabled: true, label: "Inspection❗", items: [
                    {visible: allPermissionsPresent("view_job_inspection_type"), label: "Types❗"},
                    {visible: allPermissionsPresent("view_job_inspection_template"), label: "Templates❗"}
                ]},
            ]}
        ]
        },
        {visible: allPermissionsPresent("view_equipment_section"), label:"Assets❗", items:[
            {label:"Assets❗", items: [
                {label:"View❗", url:"/v2/crm/assets"},
                {visible: allPermissionsPresent("create_equipment"), label:"Create ✅", url:"/v2/crm/assets/create"},
            ]},
            {visible: allPermissionsPresent("view_equipment_transport_note"), label:"Transport Notes❗", items: [
                {visible: allPermissionsPresent("view_equipment_transport_note"), label:"View❗", url: "/v2/crm/assets/transport_note"},
                {visible: allPermissionsPresent("create_equipment_transport_note"), disabled:true, label:"Create❗", url:"/v2/crm/assets_transport_note/create"},
            ]},
            {visible:allPermissionsPresent("view_equipment_section_config"), label:"Config❗", items:[
                {visible:allPermissionsPresent("view_make_model_section"), label:"Makes & Models❗", items: [
                    {visible:allPermissionsPresent("create_make_models"), label:"Create ✅", url:"/v2/crm/assets/makemodels/create"},
                    {visible:allPermissionsPresent("view_make_models"), label:"View❗", url:"/v2/crm/assets/makemodels/"},
                    {visible:allPermissionsPresent("view_make_model_section"), label:"Attributes❗", url:"/v2/crm/assets/attributes/"},
                ]},
                {visible:allPermissionsPresent("view_equipment_category"), label:"Categories ✅", url: "/v2/crm/assets/categories/"},
                {visible:allPermissionsPresent("view_equipment_type"), label:"Types ✅", url: "/v2/crm/assets/types/"},
                {visible:allPermissionsPresent("view_equipment_sub_type"), label:"Sub-Types ✅", url: "/v2/crm/assets/sub_types/"},
            ]}
        ]},
        {visible: allPermissionsPresent("view_hire_section"), label: "Hire❗", items: [
            {label: "Contracts❗", items: [
                {label: "View❗", url: "/v2/crm/hire_contracts"},
                {visible: allPermissionsPresent("create_hire_contract"), url: "/v2/crm/hire_contracts/create", label: "Create ✅"}, 
            ]},
            {visible:allPermissionsPresent("view_equipment_section_config"), label:"Config❗", items:[
                {visible: allPermissionsPresent("view_hire_contract_types"), url: "/v2/crm/hire_contracts/types", label: "Contract Types ✅"},
                {visible: allPermissionsPresent("bulk_update_hire_contract_charges"), disabled: true, label: "Global Uplift❗"},
            ]}
        ]},
        {visible: allPermissionsPresent("view_maintenance_section"), label:"Maintenance❗", items: [
            {label: "Contracts❗", items: [
                {label: "View❗", url: "/v2/crm/maintenance_contracts"},
                {visible: allPermissionsPresent("create_maintenance_contract"), disabled: true, label: "Create❗"}, 
            ]},
            {visible:allPermissionsPresent("view_equipment_section_config"), label:"Config❗", items:[
                {visible: allPermissionsPresent("create_maintenance_contract_type"), label: "Contract Types ✅", url: "/v2/crm/maintenance_contracts/types"},
                {visible: allPermissionsPresent("bulk_update_service_jobs"), disabled: true, label: "Update Service Jobs❗"}, 
                {visible: allPermissionsPresent("bulk_update_maintenance_contract_charges"), disabled: true, label: "Global Uplift❗"},
            ]}
        ]},
        {visible: allPermissionsPresent("view_stock_section"), label:"Stock❗", items: [
            {label:"Stock❗", items: [
                {label:"View❗", url:"/v2/crm/stock"},
                {visible: allPermissionsPresent("create_stock"), disabled:true, label:"Create"},
            ]},
            {visible: allPermissionsPresent("view_stock_adjustment"), label:"Stock Adjustments❗", items: [
                {visible: allPermissionsPresent("view_stock_adjustment"), label:"View❗", url:"/v2/crm/stock_adjustment"},
                {visible: allPermissionsPresent("create_stock_adjustment"), disabled:true, label:"Create❗"},
            ]},
            {visible: allPermissionsPresent("view_job_stock_issue"), label:"Job Stock Issues❗", items: [
                {visible: allPermissionsPresent("view_job_stock_issue"), label:"View❗", url:"/v2/crm/stock_issue"},
                {visible: allPermissionsPresent("create_job_stock_issue"), disabled:true, label:"Create❗"},
            ]},
            {visible: allPermissionsPresent("view_stock_config"), label:"Config❗", items: [
                {visible: allPermissionsPresent("view_stock_config_manufacturers"), disabled:true, label:"Manufacturers❗"},
                {visible: allPermissionsPresent("view_stock_config_stock_types"), disabled:true, label:"Stock Types❗"},
            ]},
        ]},
        {visible: allPermissionsPresent("view_purchase_order_section"), label:"Purchases❗", items:[
            {label:"Orders ✅", items: [
                {label:"View❗", url:"/v2/crm/purchase/orders"},
                {visible: allPermissionsPresent("create_purchase_order"), label:"Create ✅", url:"/v2/crm/purchase/orders/create"},
            ]},
            {visible: allPermissionsPresent("view_goods_received_note"), label:"Goods Received Notes❗", items: [
                {visible: allPermissionsPresent("view_goods_received_note"), label:"View❗", url:"/v2/crm/goods_received"},
                {visible: allPermissionsPresent("create_goods_received_note"), disabled:true, label:"Create❗", url:"/v2/crm/goods_received/create"},
            ]},
            {visible: allPermissionsPresent("view_returns_note"), disabled:true, label:"Returns Notes❗", items: [
                {visible: allPermissionsPresent("view_returns_note"), disabled:true, label:"View❗", url:"/v2/crm/returns_notes"},
                {visible: allPermissionsPresent("create_returns_note"), disabled:true, label:"Create❗", url:"/v2/crm/returns_notes/create"},
            ]},
        ]},
        {visible: allPermissionsPresent("view_sales_section"), label: "Sales❗", items: [
            {label: "Orders❗", items: [
                {label: "View❗", url: "/v2/crm/sales/orders"},
                {visible: allPermissionsPresent("create_sales_order"), label: "Create ✅", url: "/v2/crm/sales/orders/create"},
            ]},
            {visible: allPermissionsPresent("view_despatch_note"), label: "View Despatch Notes❗", url: "/v2/crm/despatch_notes"},
            {visible:allPermissionsPresent("view_sales_section_config"), label:"Config ✅", items:[
                {visible:allPermissionsPresent("view_sales_section_config_sales_areas"), label:"Sales Areas ✅", url: "/v2/crm/sales/areas"},
            ]}
        ]},
        {visible: allPermissionsPresent("view_invoice_section"), label:"Accounts❗", items:[
            { label:"Invoices❗", url:"/v2/crm/invoices"},
            {visible:allPermissionsPresent("create_supplier"), label:"Suppliers ✅", items:[
                {label: "View ✅", url: "/v2/crm/suppliers"},
                {visible: allPermissionsPresent("create_supplier"), label: "Create ✅", url: "/v2/crm/suppliers/create"}
    
            ]},
            {visible: allPermissionsPresent("view_sales_credit_note_section"), label:"Sales Credit Notes❗", items: [
                {visible: allPermissionsPresent("view_sales_credit_note_section"), label:"View❗", url:"/v2/crm/invoices/sales_credit_notes"},
                {visible: allPermissionsPresent("create_sales_credit_note"), disabled:true, label:"Create❗", url:"/v2/crm/invoices/sales_credit_notes/create"},
            ]},
            {visible: allPermissionsPresent("view_invoice_credit_note_section"), label:"Purchase Invoices/Credit Notes❗", items: [
                {visible: allPermissionsPresent("view_invoice_credit_note_section"), label:"View❗", url:"/v2/crm/purchase_invoice_credit_notes"},
                {visible: allPermissionsPresent("create_invoice_credit_note"), disabled:true, label:"Create❗", url:"/v2/crm/purchase_invoice_credit_notes/create"},
            ]},
            {visible:allPermissionsPresent("view_invoice_section_config"), label:"Config❗", items:[
                {visible:allPermissionsPresent("view_invoice_section_config"), label:"Cost Codes❗", url: "/v2/crm/accounts/cost_codes"},
                {visible: allPermissionsPresent("view_invoice_templates"), disabled:true, label:"Invoice Templates❗", url:"/v2/crm/invoices"},
            ]}
        ]},
        {visible: allPermissionsPresent("view_report_section"), label:"Reports❗", url:"/v2/reports"},
        // {label: "Config >"},
        // {label: "General", items: [
        //     {disabled:true, label:"Settings"},
        //     {disabled:true, label:"Depot, Stores & Work Areas"},
        //     {disabled:true, label:"Employees"},
        //     {disabled:true, label:"Employee Attributes"},
        //     {disabled:true, label:"Site Routes"},
        //     {disabled:true, label:"Cancellation Reasons"},
        // ]},
        // {label: "Accounts", items: [
        //     {disabled:true, label:"Periods"},
        //     {disabled:true, label:"Countries"},
        //     {disabled:true, label:"Currency"},
        //     {disabled:true, label:"VAT Rates"},
        // ]},
        // {label: "CRM", items: [
        //     {disabled:true, label:"Competitors"},
        //     {disabled:true, label:"Task Types"},
        //     {disabled:true, label:"Customer Attributes"},
        // ]},
        // {label: "Stock", items: [
        //     {disabled:true, label:"Manufacturers"},
        //     {disabled:true, label:"Stock Types"},
        // ]},
        // {label: "Equipment", items: [
        //     {disabled:true, label:"Create Makes & Models"},
        //     {disabled:true, label:"View Make & Models"},
        //     {disabled:true, label:"Attributes"},
        // ]},
        // {label: "Hire", items: [
        //     {disabled:true, label:"Contract Types"},
        //     {disabled:true, label:"Hire Extras"},
        // ]},
        // {label: "Contracts", items: [
        //     {disabled:true, label:"Contract Types"},
        //     {disabled:true, label:"Service Types"},
        //     {disabled:true, label:"Service Type Defaults"},
        // ]},
        // {label: "Job", items: [
        //     {disabled:true, label:"Job Types"},
        //     {disabled:true, label:"Labour Rates"},
        //     {disabled:true, label:"Job Type Defaults"},
        //     {disabled:true, label:"Activity Types"},
        //     {disabled:true, label:"Attributes"},
        //     {disabled:true, label:"Fault, Causes & Actions"},
        //     {disabled:true, label:"Labour Profiles"},
        //     {label: "Inspection", items: [
        //         {disabled:true, label:"Codes"},
        //         {disabled:true, label:"Attributes"},
        //         {disabled:true, label:"Templates"},
        //     ]}
        // ]},
        {label: "Config", items: [
            {label: "General", items: [
                {disabled:true, label:"Settings"},
                {disabled:true, label:"Depot, Stores & Work Areas"},
                {disabled:true, label:"Employees"},
                {disabled:true, label:"Employee Attributes"},
                {disabled:true, label:"Site Routes"},
                {disabled:true, label:"Cancellation Reasons"},
            ]},
            {label: "Accounts", items: [
                {disabled:true, label:"Periods"},
                {disabled:true, label:"Countries"},
                {disabled:true, label:"Currency"},
                {disabled:true, label:"VAT Rates"},
            ]},
            {label: "CRM", items: [
                {disabled:true, label:"Competitors"},
                {disabled:true, label:"Task Types"},
                {disabled:true, label:"Customer Attributes"},
            ]},
            {label: "Stock", items: [
                {disabled:true, label:"Manufacturers"},
                {disabled:true, label:"Stock Types"},
            ]},
            {label: "Assets", items: [
                {disabled:true, label:"Create Makes & Models"},
                {label:"View Make & Models", url:"/v2/crm/assets/makemodels/"},
                {disabled:true, label:"Attributes"},
            ]},
            {label: "Hire", items: [
                {disabled:true, label:"Contract Types"},
                {disabled:true, label:"Hire Extras"},
            ]},
            {label: "Contracts", items: [
                {disabled:true, label:"Contract Types"},
                {disabled:true, label:"Service Types"},
                {disabled:true, label:"Service Type Defaults"},
            ]},
            {label: "Job", items: [
                {disabled:true, label:"Job Types"},
                {disabled:true, label:"Labour Rates"},
                {disabled:true, label:"Job Type Defaults"},
                {disabled:true, label:"Activity Types"},
                {disabled:true, label:"Attributes"},
                {disabled:true, label:"Fault, Causes & Actions"},
                {disabled:true, label:"Labour Profiles"},
                {label: "Inspection", items: [
                    {disabled:true, label:"Codes"},
                    {disabled:true, label:"Attributes"},
                    {disabled:true, label:"Templates"},
                ]}
            ]},
        ]},
    ]
    
    // if the context is null then the data have not been fetched yet
    if (props.perm) {
        if (!allPermissionsPresent(props.perm)) {
            console.log("You do not have permission to access this page.");
            return (
                <CRMPageWrapper {...props}>
                    <FlexGroup type="column" gap="0" style={{height: "100vh"}}>
                    
                        <AcclaimMenuBar propItems={menuItems} />
                        <NoAccess>You do not have permission to access this page.</NoAccess>
                    </FlexGroup>
                </CRMPageWrapper>
            );
        }
    }

    return (
        user && !((user.id === 1 && user.full_name !== "Keian Dunnill") || user.username === "B360Guest") && <CRMPageWrapper {...props}>
            <FlexGroup type="column" gap="0" style={{height: "100vh"}}>
                <div className="">
                    <AcclaimMenuBar propItems={menuItems} />
                </div>
                {props.children}
            </FlexGroup>
        </CRMPageWrapper>
    );
}

export default CRMPage;